import React, { Component } from 'react'
import { Segment, Divider, Button, Dimmer, Loader } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import { updateCompany } from 'ducks/auth'
import { connect } from 'react-redux'

class EnableTimedTicketing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  enableTimedTicketing() {
    this.setState({ loading: true })
    axios()
      .patch('/companies', {
        usesTimedTicketing: true
      })
      .then(res => {
        this.setState({ loading: false })
        this.props.updateCompany(res)
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  render() {
    return (
      <Segment padded="very" textAlign="center">
        <Dimmer inverted active={this.state.loading}>
          <Loader />
        </Dimmer>
        <h2 style={{ marginTop: 0 }}>Timed Ticketing is Disabled</h2>
        <Divider />
        <p style={{ fontSize: 17 }}>
          Timed ticketing requires your customers to select a time slot when
          they purchase a ticket. It also allows you to limit the number of
          tickets available per time slot.
        </p>
        <Button
          color="green"
          onClick={this.enableTimedTicketing.bind(this)}
          content="Click here to enable it."
        />
      </Segment>
    )
  }
}

export default connect(null, {
  updateCompany
})(EnableTimedTicketing)
