import React, { Component } from 'react'
import { Message, Form, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class ResetPassword extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      error: '',
      password: '',
      confirmPassword: ''
    }
  }

  handleInputChange(prop, event) {
    this.setState({ [prop]: event.target.value })
  }

  handleSubmit() {
    if (this.state.loading) return
    if (!this.state.password) {
      return this.setState({ error: 'All fields must be completed.' })
    }
    if (this.state.confirmPassword !== this.state.password) {
      return this.setState({ error: "Passwords don't match." })
    }
    this.setState({ loading: true })
    axios()
      .post(
        '/users/reset-password',
        {
          password: this.state.password
        },
        {
          headers: {
            Authorization: this.props.match.params.token
          }
        }
      )
      .then(data => {
        this.props.history.push('/authentication/sign-in')
        toast.success('Password has been changed successfully.')
      })
      .catch(err => {
        this.setState({ error: err.message, loading: false })
      })
  }

  render() {
    return (
      <div>
        <h2 style={{ marginTop: 5 }}>Create new password...</h2>
        {this.state.error && (
          <Message
            negative
            header="Oops! Something went wrong."
            content={this.state.error}
          />
        )}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Input
            label="Password"
            type="password"
            onChange={this.handleInputChange.bind(this, 'password')}
            value={this.state.password}
          />
          <Form.Input
            label="Confirm Password"
            type="password"
            onChange={this.handleInputChange.bind(this, 'confirmPassword')}
            value={this.state.confirmPassword}
          />
          <Button
            loading={this.state.loading}
            fluid
            size="large"
            labelPosition="right"
            icon="right chevron"
            color="blue"
            content="Change Password"
          />
        </Form>
        <p style={{ marginTop: 10, textAlign: 'center' }}>
          I don&apos;t want to change my password.{' '}
          <Link to="/authentication/sign-in">Sign in</Link>
        </p>
      </div>
    )
  }
}

export default ResetPassword
