import React, { Component } from 'react'
import { Segment, Icon, Button, Input, Grid } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import Table from './components/Table'
import InviteUser from './components/InviteUser'
import EditUser from './components/EditUser'

class TeamMembers extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      users: [],
      currentPage: 1,
      totalPages: 0,
      search: '',
      includeDisabled: false,
      inviteUserModal: false,
      editUserModal: false,
      selectedUser: null
    }
  }

  componentDidMount() {
    this.getUsers.apply(this)
  }

  getUsers() {
    this.setState({ loading: true })

    axios()
      .get('/users', {
        params: {
          orderBy: 'firstName',
          page: this.state.currentPage,
          search: this.state.search || undefined,
          includeDisabled: this.state.includeDisabled
        }
      })
      .then(res => {
        this.setState({
          loading: false,
          users: res.results,
          currentPage: res.currentPage,
          totalPages: res.totalPages
        })
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({ loading: false })
      })
  }

  changePage(event, data) {
    if (data.activePage !== this.state.currentPage) {
      this.setState(
        {
          currentPage: data.activePage,
          search: ''
        },
        this.getUsers.bind(this)
      )
    }
  }

  toggleDisabledUsers() {
    this.setState(
      {
        includeDisabled: !this.state.includeDisabled,
        search: '',
        currentPage: 1
      },
      this.getUsers.bind(this)
    )
  }

  handleInputChange(event, data) {
    this.setState({ [event.target.name]: event.target.value || data.value })
  }

  keyUpHandle(event) {
    if (event.keyCode === 13) {
      this.search.apply(this)
    }
  }

  search() {
    this.setState({ currentPage: 1 }, this.getUsers.bind(this))
  }

  openEditUserModal(user) {
    this.setState({
      selectedUser: user,
      editUserModal: true
    })
  }

  render() {
    return (
      <Segment loading={this.state.loading}>
        <h2>Team Members</h2>

        <InviteUser
          open={this.state.inviteUserModal}
          onClose={() => this.setState({ inviteUserModal: false })}
        />

        <EditUser
          user={this.state.selectedUser}
          getUsers={this.getUsers.bind(this)}
          open={this.state.editUserModal}
          onClose={() => this.setState({ editUserModal: false })}
        />

        <Grid stackable reversed="mobile" padded={false}>
          <Grid.Column computer={4}>
            <Input
              fluid
              icon={
                <Icon name="search" link onClick={this.search.bind(this)} />
              }
              placeholder="Search..."
              name="search"
              value={this.state.search}
              onKeyUp={this.keyUpHandle.bind(this)}
              onChange={this.handleInputChange.bind(this)}
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4}>
            <Button
              fluid
              color="red"
              onClick={this.toggleDisabledUsers.bind(this)}
              content={
                this.state.includeDisabled ? 'Hide Disabled' : 'Show Disabled'
              }
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4} floated="right">
            <Button
              fluid
              color="blue"
              icon="add"
              onClick={() => this.setState({ inviteUserModal: true })}
              content="Add Members"
            />
          </Grid.Column>
        </Grid>
        <Table
          users={this.state.users}
          openEditUserModal={this.openEditUserModal.bind(this)}
          changePage={this.changePage.bind(this)}
          currentPage={this.state.currentPage}
          totalPages={this.state.totalPages}
        />
      </Segment>
    )
  }
}

export default TeamMembers
