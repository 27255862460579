import React, { Component } from 'react'
import { Table, Pagination, Icon, Responsive, Button } from 'semantic-ui-react'

class UsersTable extends Component {
  render() {
    return (
      <Table striped unstackable>
        <Responsive minWidth="800" as={Table.Header}>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Admin</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Responsive>

        <Table.Body>
          {!this.props.users.length && (
            <Table.Row>
              <Table.Cell colSpan="100" textAlign="center">
                No Users Found...
              </Table.Cell>
            </Table.Row>
          )}

          {this.props.users.map(user => (
            <Table.Row key={user.id}>
              <Table.Cell>
                <strong>
                  {user.disabled ? <Icon color="red" name="ban" /> : null}
                  {user.firstName} {user.lastName}
                </strong>
              </Table.Cell>
              <Responsive minWidth="800" as={Table.Cell}>
                {user.email}
              </Responsive>
              <Responsive minWidth="800" as={Table.Cell}>
                {user.phone}
              </Responsive>
              <Responsive minWidth="800" as={Table.Cell} textAlign="center">
                {user.isAdmin ? <Icon name="check" /> : null}
              </Responsive>
              <Table.Cell textAlign="right">
                <Button onClick={() => this.props.openEditUserModal(user)}>
                  Edit/View
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="100" textAlign="right">
              <Pagination
                onPageChange={this.props.changePage}
                nextItem={{ content: <Icon name="chevron right" /> }}
                prevItem={{ content: <Icon name="chevron left" /> }}
                lastItem={null}
                firstItem={null}
                ellipsisItem={null}
                boundaryRange={0}
                size="mini"
                activePage={this.props.currentPage}
                totalPages={this.props.totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }
}

export default UsersTable
