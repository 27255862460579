import React from 'react'
import moment from 'moment-timezone'
import { Icon } from 'semantic-ui-react'

export default function(props) {
  return (
    <div
      style={{
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'space-around'
      }}
    >
      <div>
        <strong>{moment(props.timeSlot.startAt).format('h:mm A')}</strong>
        <br />
        {moment(props.timeSlot.startAt).format('LL')}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
        <Icon name="long arrow alternate right" />
      </div>
      <div>
        <strong>{moment(props.timeSlot.endAt).format('h:mm A')}</strong>
        <br />
        {moment(props.timeSlot.endAt).format('LL')}
      </div>
    </div>
  )
}
