import moment from 'moment-timezone'

const UPDATE_COMPANY = 'auth/UPDATE_COMPANY'
const UPDATE_USER = 'auth/UPDATE_USER'
const CLEAR_USER = 'auth/CLEAR_USER'
const CLEAR_COMPANY = 'auth/CLEAR_COMPANY'

const initialState = {
  user: null,
  company: null
}

// REDUCER
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_COMPANY:
      return {
        user: state.user,
        company: {
          ...state.company,
          ...action.payload
        }
      }
    case UPDATE_USER:
      return {
        user: {
          ...state.user,
          ...action.payload
        },
        company: state.company
      }
    case CLEAR_USER:
      return {
        user: initialState.user,
        company: state.company
      }
    case CLEAR_COMPANY:
      return {
        user: state.user,
        company: initialState.company
      }
    default:
      return state
  }
}

// ACTIONS
export const updateCompany = values => {
  window.drift.on('ready', () => {
    window.drift.api.setUserAttributes({
      companyName: values.name,
      companyId: values.id
    })
  })

  moment.tz.setDefault(values.timeZone)

  return {
    type: UPDATE_COMPANY,
    payload: values
  }
}

export const clearCompany = () => {
  return {
    type: CLEAR_COMPANY
  }
}

export const updateUser = values => {
  if (values.token) {
    window.localStorage.setItem('token', values.token)
  }

  window.drift.on('ready', () => {
    window.drift.api.setUserAttributes({
      name: `${values.firstName} ${values.lastName}`,
      email: values.email,
      phone: values.phone,
      userId: values.id
    })
  })

  return {
    type: UPDATE_USER,
    payload: values
  }
}

export const clearUser = () => {
  window.localStorage.removeItem('token')

  return {
    type: CLEAR_USER
  }
}
