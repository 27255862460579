import React, { Component } from 'react'
import { Segment, Dimmer } from 'semantic-ui-react'
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import COLORS from '../../helpers/colors.helper'

class ByMarketing extends Component {
  render() {
    let data = [
      { medium: 'Social Media', tickets: 500 },
      { medium: 'Radio', tickets: 733 },
      { medium: 'Billboard', tickets: 430 }
    ]

    if (this.props.data && this.props.data.length) {
      data = this.props.data.map(el => {
        return {
          medium: el.medium,
          tickets: Number(el.tickets)
        }
      })
    }

    return (
      <Segment>
        <Dimmer
          active={!(this.props.data && this.props.data.length > 0)}
          inverted
        />
        <h3 style={{ marginTop: 0 }}>Marketing Mediums</h3>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Tooltip />
            <Legend />
            <Pie
              dataKey="tickets"
              nameKey="medium"
              innerRadius={65}
              data={data}
            >
              {data.map((c, i) => (
                <Cell key={i} fill={COLORS[i % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Segment>
    )
  }
}

export default ByMarketing
