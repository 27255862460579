import React, { Component } from 'react'
import { Message, Form, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class ForgotPassword extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      error: '',
      email: ''
    }
  }

  handleInputChange(prop, event) {
    this.setState({ [prop]: event.target.value })
  }

  handleSubmit() {
    if (this.state.loading) return
    if (!this.state.email) {
      return this.setState({ error: 'All fields must be completed.' })
    }
    this.setState({ loading: true })
    axios()
      .post('/users/forgot-password', {
        email: this.state.email
      })
      .then(data => {
        this.props.history.push('/authentication/sign-in')
        toast.success('Check your email for a link to reset your password.')
      })
      .catch(err => {
        this.setState({ error: err.message, loading: false })
      })
  }

  render() {
    return (
      <div>
        <h2 style={{ marginTop: 5 }}>Request a password reset link...</h2>
        {this.state.error && (
          <Message
            negative
            header="Oops! Something went wrong."
            content={this.state.error}
          />
        )}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Input
            label="Email"
            onChange={this.handleInputChange.bind(this, 'email')}
            value={this.state.email}
          />
          <Button
            loading={this.state.loading}
            fluid
            size="large"
            labelPosition="right"
            icon="right chevron"
            color="blue"
            content="Send Link"
          />
        </Form>
        <p style={{ marginTop: 10, textAlign: 'center' }}>
          Oh wait, I remember! <Link to="/authentication/sign-in">Sign in</Link>
        </p>
      </div>
    )
  }
}

export default ForgotPassword
