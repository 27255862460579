import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, List, Icon, Message } from 'semantic-ui-react'
import { updateCompany } from 'ducks/auth'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class GoLive extends Component {
  constructor() {
    super()

    this.state = {
      loading: false
    }
  }

  submit() {
    if (this.state.loading) return

    if (
      !this.props.company.isLive &&
      this.props.company.stripeStatus !== 'active'
    ) {
      return toast.error(
        'Please verify your Stipe account is connected before going live.'
      )
    }

    this.setState({ loading: true })

    axios()
      .patch('/companies', {
        isLive: !this.props.company.isLive
      })
      .then(res => {
        this.props.updateCompany(res)
        toast.success('Update successful.')
        this.setState({ loading: false })
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({ loading: false })
      })
  }

  render() {
    const frontEndUrl = `${process.env.REACT_APP_TICKET_PORTAL_URL}/${this.props.company.frontEndUrl}`
    return (
      <Modal
        size="tiny"
        centered
        closeOnDimmerClick={false}
        open={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <Modal.Header>Ticketing Portal</Modal.Header>
        {this.props.company.isLive && (
          <Modal.Content>
            <p>
              Your ticketing portal is live. Use the link below to start selling
              tickets. It&#39;s a good idea to buy a ticket then refund it to
              test that everything is working correctly.
            </p>
            <Message>
              <a target="__blank" href={frontEndUrl}>
                {frontEndUrl}
              </a>
            </Message>
            <p>
              You can turn off ticket sales at anytime by clicking the button
              below.
            </p>
          </Modal.Content>
        )}
        {!this.props.company.isLive && (
          <Modal.Content>
            <p>
              Going live will allow customers to buy tickets for your event.
              Before going live, please check the following items.
            </p>
            <List>
              <List.Item>
                <Icon name="check" />
                Bank account is connected and verified.
              </List.Item>
              <List.Item>
                <Icon name="check" />
                At least one type of ticket exists.
              </List.Item>
              <List.Item>
                <Icon name="check" />
                Company logo and other options are set in the &#39;Settings&#39;
                page.
              </List.Item>
              <List.Item>
                <Icon name="check" />
                The ticket scanning app has been installed, so you can scan
                tickets.
              </List.Item>
            </List>
            <p>
              If you would like your account manager to look over your settings
              before going live, click on the blue chat icon in the bottom
              right-hand corner.
            </p>
          </Modal.Content>
        )}

        <Modal.Actions>
          <Button onClick={this.props.onClose}>Done</Button>
          <Button
            color={this.props.company.isLive ? 'red' : 'green'}
            loading={this.state.loading}
            onClick={this.submit.bind(this)}
          >
            {this.props.company.isLive ? 'Turn Off' : 'Go Live'}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default connect(state => ({ company: state.auth.company }), {
  updateCompany
})(GoLive)
