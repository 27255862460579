import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Segment, Divider, Form } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { updateUser } from 'ducks/auth'
import { toast } from 'react-toastify'

class UserSettings extends Component {
  constructor(props) {
    super(props)

    this.initialState = {
      loading: false,
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      email: props.user.email,
      phone: props.user.phone
    }

    this.state = this.initialState
  }

  handleInputChange(prop, event, data) {
    this.setState({ [prop]: event.target.value || data.value })
  }

  handleSubmit() {
    if (this.state.loading) return

    this.setState({ loading: true })
    axios()
      .patch('/users', {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone
      })
      .then(res => {
        this.props.updateUser(res)
        toast.success('Updated successfully.')
        this.setState({ loading: false })
        this.initialState = this.state
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({ loading: false })
      })
  }

  handleReset(e) {
    e.preventDefault()
    this.setState(this.initialState)
  }

  render() {
    return (
      <Segment clearing>
        <h2>User Settings</h2>
        <Divider />
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Input
            label="First Name"
            value={this.state.firstName}
            onChange={this.handleInputChange.bind(this, 'firstName')}
          />
          <Form.Input
            label="Last Name"
            value={this.state.lastName}
            onChange={this.handleInputChange.bind(this, 'lastName')}
          />
          <Form.Input
            label="Email"
            value={this.state.email}
            onChange={this.handleInputChange.bind(this, 'email')}
          />
          <Form.Input
            label="Phone"
            value={this.state.phone}
            onChange={this.handleInputChange.bind(this, 'phone')}
          />
          <Form.Group style={{ float: 'right' }}>
            <Form.Button
              onClick={this.handleReset.bind(this)}
              content="Reset"
            />
            <Form.Button
              loading={this.state.loading}
              color="green"
              content="Save"
            />
          </Form.Group>
        </Form>
      </Segment>
    )
  }
}

export default connect(
  state => ({
    user: state.auth.user
  }),
  {
    updateUser
  }
)(UserSettings)
