import React, { Component } from 'react'
import { Form, Button, Message, Progress } from 'semantic-ui-react'
import { updateUser, updateCompany } from 'ducks/auth'
import { connect } from 'react-redux'
import axios from 'helpers/axios.helper'
import { Link } from 'react-router-dom'

class UserSignUp extends Component {
  constructor() {
    super()

    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
      error: ''
    }
  }

  componentDidMount() {
    if (!this.props.location.state || !this.props.location.state.token) {
      this.props.history.push('/authentication/sign-up')
    }
  }

  handleInputChange(prop, event) {
    this.setState({ [prop]: event.target.value })
  }

  handleSubmit() {
    if (this.state.loading) return
    if (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.phone ||
      !this.state.email ||
      !this.state.password
    ) {
      return this.setState({ error: 'All fields must be completed.' })
    }
    if (this.state.password !== this.state.confirmPassword) {
      return this.setState({ error: "Passwords don't match." })
    }
    this.setState({ loading: true })
    axios()
      .post('/authentication/sign-up/user', {
        companyToken: this.props.location.state.token,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        email: this.state.email,
        password: this.state.password
      })
      .then(data => {
        this.props.updateCompany(data.company)
        this.props.updateUser(data.user)
      })
      .catch(err => {
        this.setState({ error: err.message, loading: false })
      })
  }

  render() {
    return (
      <div>
        <Progress value="2" total="2" progress="ratio" color="green" />
        <h2>Tell us a little about yourself...</h2>
        {this.state.error && (
          <Message
            negative
            header="Oops! Something went wrong."
            content={this.state.error}
          />
        )}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Group widths="equal">
            <Form.Input
              required
              label="First Name"
              onChange={this.handleInputChange.bind(this, 'firstName')}
              value={this.state.firstName}
            />
            <Form.Input
              required
              label="Last Name"
              onChange={this.handleInputChange.bind(this, 'lastName')}
              value={this.state.lastName}
            />
          </Form.Group>
          <Form.Input
            required
            label="Phone"
            onChange={this.handleInputChange.bind(this, 'phone')}
            value={this.state.phone}
          />
          <Form.Input
            required
            label="Email"
            onChange={this.handleInputChange.bind(this, 'email')}
            value={this.state.email}
          />
          <Form.Input
            required
            label="Password"
            type="password"
            onChange={this.handleInputChange.bind(this, 'password')}
            value={this.state.password}
          />
          <Form.Input
            required
            label="Confirm Password"
            type="password"
            onChange={this.handleInputChange.bind(this, 'confirmPassword')}
            value={this.state.confirmPassword}
          />
          <Button
            loading={this.state.loading}
            fluid
            size="large"
            labelPosition="right"
            icon="right chevron"
            color="blue"
            content="Sign Up"
          />
        </Form>
        <p style={{ marginTop: 10, textAlign: 'center' }}>
          Already have an account?{' '}
          <Link to="/authentication/sign-in">Sign in</Link>
        </p>
      </div>
    )
  }
}

export default connect(null, {
  updateUser,
  updateCompany
})(UserSignUp)
