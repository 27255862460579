import React, { Component } from 'react'
import { Message, Form, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class AcceptInvitation extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      error: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: ''
    }
  }

  handleInputChange(event, data) {
    this.setState({
      [event.target.name || data.name]:
        event.target.value || data.value || data.checked
    })
  }

  handleSubmit() {
    if (this.state.loading) return

    if (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.email ||
      !this.state.phone ||
      !this.state.password
    ) {
      return this.setState({ error: 'All fields must be completed.' })
    }

    if (this.state.password !== this.state.confirmPassword) {
      return this.setState({ error: "Passwords don't match." })
    }

    this.setState({ loading: true })

    axios()
      .post(
        '/users/accept-invitation',
        {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          phone: this.state.phone,
          password: this.state.password
        },
        {
          headers: {
            Authorization: this.props.match.params.token
          }
        }
      )
      .then(data => {
        toast.success('Account created successfully.')
        this.props.history.push('/authentication/sign-in')
      })
      .catch(err => {
        this.setState({ error: err.message, loading: false })
      })
  }

  render() {
    return (
      <div>
        <h2 style={{ marginTop: 5 }}>Create an account...</h2>
        {this.state.error && (
          <Message
            negative
            header="Oops! Something went wrong."
            content={this.state.error}
          />
        )}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Input
            label="First Name"
            name="firstName"
            onChange={this.handleInputChange.bind(this)}
            value={this.state.firstName}
          />
          <Form.Input
            label="Last Name"
            name="lastName"
            onChange={this.handleInputChange.bind(this)}
            value={this.state.lastName}
          />
          <Form.Input
            label="Email"
            name="email"
            onChange={this.handleInputChange.bind(this)}
            value={this.state.email}
          />
          <Form.Input
            label="Phone Number"
            name="phone"
            onChange={this.handleInputChange.bind(this)}
            value={this.state.phone}
          />
          <Form.Input
            label="Password"
            name="password"
            type="password"
            onChange={this.handleInputChange.bind(this)}
            value={this.state.password}
          />
          <Form.Input
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            onChange={this.handleInputChange.bind(this)}
            value={this.state.confirmPassword}
          />
          <Button
            loading={this.state.loading}
            fluid
            size="large"
            labelPosition="right"
            icon="right chevron"
            color="blue"
            content="Create Account"
          />
        </Form>
        <p style={{ marginTop: 10, textAlign: 'center' }}>
          I already have an account.{' '}
          <Link to="/authentication/sign-in">Sign in</Link>
        </p>
      </div>
    )
  }
}

export default AcceptInvitation
