import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Segment, Divider, Form, Popup, Icon } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { updateCompany } from 'ducks/auth'
import { toast } from 'react-toastify'
import states from 'helpers/states.json'
import TimeZones from 'components/TimeZones'

class CompanySettings extends Component {
  constructor(props) {
    super(props)

    this.initialState = {
      loading: false,
      name: props.company.name,
      address: props.company.address,
      city: props.company.city,
      state: props.company.state,
      zipCode: props.company.zipCode,
      website: props.company.website,
      timeZone: props.company.timeZone,
      timeSlotPaddingBefore: props.company.timeSlotPaddingBefore,
      timeSlotPaddingAfter: props.company.timeSlotPaddingAfter
    }

    this.state = this.initialState
  }

  handleInputChange(prop, event, data) {
    this.setState({
      [prop]: event.target.value || data.value || data.checked || ''
    })
  }

  handleSubmit() {
    if (this.state.loading) return

    this.setState({ loading: true })
    axios()
      .patch('/companies', {
        name: this.state.name,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zipCode: this.state.zipCode,
        website: this.state.website,
        timeZone: this.state.timeZone,
        timeSlotPaddingBefore: this.state.timeSlotPaddingBefore,
        timeSlotPaddingAfter: this.state.timeSlotPaddingAfter
      })
      .then(res => {
        this.props.updateCompany(res)
        toast.success('Updated successfully.')
        this.setState({ loading: false })
        this.initialState = this.state
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({ loading: false })
      })
  }

  handleReset(e) {
    e.preventDefault()
    this.setState(this.initialState)
  }

  render() {
    return (
      <Segment clearing>
        <h2>Company Settings</h2>
        <Divider />
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Input
            label="Company Name"
            value={this.state.name}
            onChange={this.handleInputChange.bind(this, 'name')}
          />
          <Form.Input
            label="Address"
            value={this.state.address}
            onChange={this.handleInputChange.bind(this, 'address')}
          />
          <Form.Group widths="equal">
            <Form.Input
              label="City"
              fluid
              value={this.state.city}
              onChange={this.handleInputChange.bind(this, 'city')}
            />
            <Form.Dropdown
              label="State"
              fluid
              search
              selection
              options={states}
              value={this.state.state}
              searchInput={{ autoComplete: 'nope' }}
              onChange={this.handleInputChange.bind(this, 'state')}
            />
            <Form.Input
              label="Zip Code"
              fluid
              value={this.state.zipCode}
              onChange={this.handleInputChange.bind(this, 'zipCode')}
            />
          </Form.Group>
          <Form.Input
            label="Website"
            value={this.state.website}
            onChange={this.handleInputChange.bind(this, 'website')}
          />
          <Form.Field>
            <label>Time Zone</label>
            <TimeZones
              value={this.state.timeZone}
              onChange={this.handleInputChange.bind(this, 'timeZone')}
            />
          </Form.Field>
          <Form.Field style={{ marginBottom: 0 }}>
            <label>
              Time Slot Padding{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="These fields specify the amount of minutes before and after a time slot starts and ends when a ticket can be redeemed without showing an error."
              />
            </label>
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Input
              label="Minutes Before"
              value={this.state.timeSlotPaddingBefore}
              onChange={this.handleInputChange.bind(
                this,
                'timeSlotPaddingBefore'
              )}
            />
            <Form.Input
              label="Minutes After"
              value={this.state.timeSlotPaddingAfter}
              onChange={this.handleInputChange.bind(
                this,
                'timeSlotPaddingAfter'
              )}
            />
          </Form.Group>

          <Form.Group style={{ float: 'right' }}>
            <Form.Button
              onClick={this.handleReset.bind(this)}
              content="Reset"
            />
            <Form.Button
              loading={this.state.loading}
              color="green"
              content="Save"
            />
          </Form.Group>
        </Form>
      </Segment>
    )
  }
}

export default connect(
  state => ({
    company: state.auth.company
  }),
  { updateCompany }
)(CompanySettings)
