import React, { Component } from 'react'
import { Modal, Button, Message, Icon, Label } from 'semantic-ui-react'
import moment from 'moment-timezone'
import Refund from './components/Refund'
import RejectRefund from './components/RejectRefund'
import ResendTicketsModal from './components/ResendTickets'

class TransactionDetails extends Component {
  constructor() {
    super()

    this.state = {
      showRefundConfirm: false,
      showRejectRefund: false,
      showResendTickets: false
    }
  }

  render() {
    if (!this.props.transaction) return null

    const transaction = this.props.transaction
    const right = {
      textAlign: 'right',
      whiteSpace: 'nowrap',
      paddingRight: 10
    }

    return (
      <Modal
        size="tiny"
        closeOnDimmerClick={false}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            Transaction Details
            <div style={{ textAlign: 'center' }}>
              {transaction.stripeRefundId && <Label content="Refunded" />}
              {!transaction.stripeRefundId &&
                transaction.refundRequestReason &&
                !transaction.refundRejectionReason && (
                  <Label color="yellow" content="Refund Requested" />
                )}
            </div>
          </div>
        </Modal.Header>

        <Modal.Content>
          {!transaction.stripeRefundId &&
            transaction.refundRequestReason &&
            !transaction.refundRejectionReason && (
              <Message error>
                Customer requested a refund for the following reason.
                <Message size="small" style={{ marginTop: 5, marginBottom: 2 }}>
                  &quot;{transaction.refundRequestReason}&quot;
                </Message>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    onClick={() => this.setState({ showRefundConfirm: true })}
                  >
                    Approve Refund
                  </Button>
                  <Button
                    onClick={() => this.setState({ showRejectRefund: true })}
                  >
                    Reject Refund
                  </Button>
                </div>
              </Message>
            )}

          <table style={{ borderSpacing: '0 7px' }}>
            <tbody>
              <tr>
                <td style={right}>
                  <strong>Purchase Date:</strong>
                </td>
                <td>{moment(transaction.purchasedAt).format('LLL')}</td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Redemption Date:</strong>
                </td>
                <td>
                  {transaction.tickets.find(t => t.redeemedAt)
                    ? moment(
                        transaction.tickets.find(t => t.redeemedAt).redeemedAt
                      ).format('LL')
                    : 'Not Redeemed'}
                </td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Tickets Redeemed:</strong>
                </td>
                <td>
                  {transaction.tickets.filter(t => t.redeemedAt).length} of{' '}
                  {transaction.numberOfTickets}
                </td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Customer Name:</strong>
                </td>
                <td>{transaction.customerName}</td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Customer Email:</strong>
                </td>
                <td>{transaction.customerEmail}</td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Time Slot:</strong>
                </td>
                <td>
                  {transaction.timeSlot && (
                    <span>
                      {moment(transaction.timeSlot.startAt).format('lll')}{' '}
                      <Icon name="long arrow alternate right" />
                      {moment(transaction.timeSlot.endAt).format('lll')}
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Ticket Type:</strong>
                </td>
                <td>{transaction.ticketType.name}</td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Promo Code:</strong>
                </td>
                <td>
                  {transaction.promoCode ? transaction.promoCode.code : 'N/A'}
                </td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Number of Tickets:</strong>
                </td>
                <td>{transaction.numberOfTickets}</td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Sales Tax:</strong>
                </td>
                <td>${transaction.salesTax.toFixed(2)}</td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Ticket Fee:</strong>
                </td>
                <td>${transaction.fees.toFixed(2)}</td>
              </tr>
              <tr>
                <td style={right}>
                  <strong>Total Charge:</strong>
                </td>
                <td>${transaction.orderTotal.toFixed(2)}</td>
              </tr>
              {Boolean(transaction.stripeRefundId) && (
                <tr>
                  <td style={right}>
                    <strong>Amount Refunded:</strong>
                  </td>
                  <td>${transaction.amountRefunded.toFixed(2)}</td>
                </tr>
              )}
            </tbody>
          </table>

          <Refund
            transaction={transaction}
            closeParent={this.props.onClose}
            onClose={() => this.setState({ showRefundConfirm: false })}
            getTransactions={this.props.getTransactions}
            open={this.state.showRefundConfirm}
          />
          <RejectRefund
            transactionId={transaction.id}
            closeParent={this.props.onClose}
            onClose={() => this.setState({ showRejectRefund: false })}
            getTransactions={this.props.getTransactions}
            open={this.state.showRejectRefund}
          />
          <ResendTicketsModal
            transactionId={transaction.id}
            customerEmail={transaction.customerEmail}
            onClose={() => this.setState({ showResendTickets: false })}
            open={this.state.showResendTickets}
            getTransactions={this.props.getTransactions}
            closeParent={this.props.onClose}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button
            onClick={() => this.setState({ showRefundConfirm: true })}
            floated="left"
          >
            Refund
          </Button>
          <Button
            onClick={() => this.setState({ showResendTickets: true })}
            floated="left"
          >
            Resend Tickets
          </Button>
          <Button onClick={this.props.onClose} color="blue">
            Done
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default TransactionDetails
