import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

class ByDate extends Component {
  render() {
    if (!this.props.data) return null

    const data = this.props.data.map(d => {
      d.tickets = Number(d.tickets)
      return d
    })

    return (
      <Segment>
        <h3>Tickets Sold by Date</h3>
        <ResponsiveContainer width="100%" height={250}>
          <AreaChart data={data}>
            <XAxis dataKey="date" />
            <YAxis type="number" width={45} />
            <Tooltip />
            <Area
              dataKey="tickets"
              stroke="#2185d0"
              name="Tickets Sold"
              fill="#2185d0"
              type="monotone"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Segment>
    )
  }
}

export default ByDate
