import React, { Component } from 'react'
import { Modal, Button, Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'
import axios from 'helpers/axios.helper.js'
import { toast } from 'react-toastify'
import { updateUser, updateCompany } from 'ducks/auth'

class AdminCompanyChange extends Component {
  constructor() {
    super()

    this.state = {
      companies: [],
      companyId: '',
      loading: false
    }
  }

  changeCompany() {
    if (this.state.loading) return
    this.setState({ loading: true })

    axios()
      .patch('/authentication/change-company', {
        companyId: this.state.companyId
      })
      .then(res => {
        window.location.reload()
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({ loading: false })
      })
  }

  searchCompanies(event, data) {
    axios()
      .get('/companies', {
        params: {
          search: data.searchQuery || null
        }
      })
      .then(res => {
        const companies = res.results.map(company => ({
          key: company.id,
          value: company.id,
          text: `${company.name} - ${company.city}, ${company.state}`
        }))
        this.setState({ companies })
      })
      .catch(err => {
        toast.error(err.message)
      })
  }

  handleChange(event, data) {
    this.setState({ companyId: data.value })
  }

  render() {
    return (
      <Modal
        size="tiny"
        closeOnDimmerClick={false}
        open={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <Modal.Header>Change Company</Modal.Header>
        <Modal.Content>
          <Dropdown
            placeholder="Company Name"
            search
            fluid
            selection
            onSearchChange={this.searchCompanies.bind(this)}
            onChange={this.handleChange.bind(this)}
            options={this.state.companies}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button
            positive
            loading={this.state.loading}
            onClick={this.changeCompany.bind(this)}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    company: state.auth.company,
    user: state.auth.user
  }),
  {
    updateUser,
    updateCompany
  }
)(AdminCompanyChange)
