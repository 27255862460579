import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'
const googleAutocomplete = new window.google.maps.places.AutocompleteService()
const googleGeocoder = new window.google.maps.Geocoder()

class GoogleAddress extends Component {
  constructor() {
    super()

    this.state = {
      searchText: '',
      value: '',
      addressSearchOptions: [],
      timedOut: false,
      runOnTimeEnd: false
    }
  }

  search() {
    if (!this.state.searchText) {
      return
    }

    googleAutocomplete.getPlacePredictions(
      {
        input: this.state.searchText,
        componentRestrictions: {
          country: 'us'
        }
      },
      places => {
        if (!places) {
          return
        }

        const addressSearchOptions = places.map(place => ({
          key: place.id,
          value: place.place_id,
          text: place.description
        }))

        setTimeout(() => {
          this.setState({ timedOut: false })

          if (this.state.runOnTimeEnd && !this.state.value) {
            this.search.apply(this)
          }
        }, 2000)

        this.setState({
          addressSearchOptions: addressSearchOptions,
          timedOut: true,
          runOnTimeEnd: false
        })
      }
    )
  }

  handleSearchChange(event) {
    this.setState({ searchText: event.target.value })

    if (this.state.timedOut) {
      return this.setState({
        runOnTimeEnd: true
      })
    }

    this.search.apply(this)
  }

  handleChange(event, data) {
    this.setState({
      value: data.value
    })

    googleGeocoder.geocode(
      {
        placeId: data.value
      },
      address => {
        if (!address[0]) return
        const addressComponents = address[0].address_components
        const streetNumber = addressComponents.find(c =>
          c.types.includes('street_number')
        )
        const street = addressComponents.find(c => c.types.includes('route'))
        const city = addressComponents.find(c => c.types.includes('locality'))
        const state = addressComponents.find(c =>
          c.types.includes('administrative_area_level_1')
        )
        const zipCode = addressComponents.find(c =>
          c.types.includes('postal_code')
        )

        if (!streetNumber || !street || !city || !state || !zipCode) {
          this.props.onChange({
            address: '',
            city: '',
            state: '',
            zipCode: ''
          })

          return this.setState({
            value: ''
          })
        }

        this.props.onChange({
          address: `${streetNumber ? streetNumber.short_name : ''} ${
            street ? street.short_name : ''
          }`,
          city: city ? city.short_name : '',
          state: state ? state.short_name : '',
          zipCode: zipCode ? zipCode.short_name : ''
        })
      }
    )
  }

  render() {
    return (
      <div style={{ textAlign: 'right', marginBottom: -15 }}>
        <Dropdown
          {...this.props}
          search={options => options}
          selection
          fluid
          options={this.state.addressSearchOptions}
          minCharacters={3}
          autoComplete="new-password"
          value={this.state.value}
          onChange={this.handleChange.bind(this)}
          onSearchChange={this.handleSearchChange.bind(this)}
        />
        <img
          src="https://storage.googleapis.com/haunted-ticketing/powered_by_google.png"
          style={{
            width: 100,
            margin: 5
          }}
          alt="Powered by Google"
        />
      </div>
    )
  }
}

export default GoogleAddress
