import React, { Component } from 'react'
import { Segment, Container } from 'semantic-ui-react'
import styled from 'styled-components'
import { connect } from 'react-redux'

const StyledSegment = styled(Segment)`
  position: absolute !important;
  bottom: 0;
  width: 100%;
  padding: 10px;
`

const Logo = styled.img`
  height: 115px;
  padding-bottom: 2px;
`

class Footer extends Component {
  render() {
    return (
      <StyledSegment inverted vertical>
        <Container textAlign="center">
          <Logo src={process.env.REACT_APP_LOGO_URL} alt="Logo" />
          <p style={{ marginBottom: 5 }}>
            <a
              href="https://www.hauntedticketing.com/terms-conditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms Of Service
            </a>{' '}
            |{' '}
            <a
              href="https://www.hauntedticketing.com/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
          <p>Haunted Ticketing, LLC &copy; {new Date().getFullYear()}</p>
        </Container>
      </StyledSegment>
    )
  }
}

export default connect(state => ({
  user: state.auth.user
}))(Footer)
