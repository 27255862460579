import React, { useEffect, useState } from 'react'
import { Confirm, Form } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

const RefundModal = props => {
  const { transaction, getTransactions, onClose, open, closeParent } = props
  const [loading, setLoading] = useState(false)
  const [forceConfirm, setForceConfirm] = useState(false)
  const [error, setError] = useState('')
  const [amount, setAmount] = useState(0)
  const [ticketIds, setTicketIds] = useState([])

  useEffect(() => {
    setAmount(transaction.orderTotal)
    setTicketIds(transaction.tickets.map(t => t.id))
  }, [transaction])

  const refund = force => {
    setLoading(true)
    axios()
      .patch(
        '/transactions/refund/' + transaction.id,
        { amount: amount, ticketIds: ticketIds },
        {
          params: {
            force: force
          }
        }
      )
      .then(res => {
        setLoading(false)
        setForceConfirm(false)
        toast.success('Refunded successfully.')
        getTransactions()
        onClose()
        closeParent()
      })
      .catch(err => {
        setLoading(false)
        setForceConfirm(false)
        if (err.canForce) {
          setForceConfirm(true)
          setError(err.message)
        } else {
          setLoading(false)
          toast.error(err.message)
        }
      })
  }

  return (
    <div>
      <Confirm
        onCancel={onClose}
        closeOnDimmerClick={false}
        onConfirm={() => refund()}
        size="tiny"
        header="Are you sure you want to refund this transaction?"
        content={
          <Form style={{ padding: 16 }}>
            <Form.Field>
              <Form.Input
                icon="dollar"
                iconPosition="left"
                label="Refund Amount"
                value={amount}
                onChange={e => setAmount(e.target.value)}
              />
            </Form.Field>
            <Form.Group grouped>
              <label>Tickets</label>
              {transaction.tickets.map((ticket, idx) => (
                <Form.Field
                  key={ticket.id}
                  label={`Ticket #${idx + 1}${
                    ticket.refundedAt ? ' - REFUNDED' : ''
                  }${ticket.redeemedAt ? ' - REDEEMED' : ''}`}
                  control="input"
                  type="checkbox"
                  checked={ticketIds.includes(ticket.id)}
                  onChange={e =>
                    e.target.checked
                      ? setTicketIds(ids => ids.concat(ticket.id))
                      : setTicketIds(ids => ids.filter(id => id !== ticket.id))
                  }
                />
              ))}
            </Form.Group>
          </Form>
        }
        confirmButton={{
          content: 'Yes',
          loading: loading
        }}
        open={open}
      />
      <Confirm
        onCancel={() => setForceConfirm(false)}
        closeOnDimmerClick={false}
        onConfirm={() => refund(true)}
        size="tiny"
        content={null}
        header={error}
        confirmButton={{
          content: 'Yes',
          loading: loading
        }}
        open={forceConfirm}
      />
    </div>
  )
}

export default RefundModal
