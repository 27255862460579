import React, { Component } from 'react'
import { Segment, Dimmer } from 'semantic-ui-react'
import {
  PieChart,
  Legend,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import COLORS from '../../helpers/colors.helper'

class ByZipCode extends Component {
  render() {
    let data = [
      { label: 'Boise, ID', tickets: 1000 },
      { label: 'Burley, ID', tickets: 233 },
      { label: 'Twin Falls, ID', tickets: 430 }
    ]

    if (this.props.data && this.props.data.length) {
      data = this.props.data.slice(0, 10).map(el => {
        el.label = el.zipCodeLookup
          ? `${el.zipCodeLookup.city}, ${el.zipCodeLookup.state}`
          : el.zipCode || 'None'
        el.tickets = Number(el.tickets)
        return el
      })
    }

    return (
      <Segment>
        <Dimmer
          active={!(this.props.data && this.props.data.length > 0)}
          inverted
        />
        {/* <a style={{ float: 'right' }}>View All</a> */}
        <h3 style={{ marginTop: 0 }}>Cities</h3>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Tooltip />
            <Legend />
            <Pie dataKey="tickets" nameKey="label" innerRadius={65} data={data}>
              {data.map((c, i) => (
                <Cell key={i} fill={COLORS[i % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Segment>
    )
  }
}

export default ByZipCode
