import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Wrapper from './components/Wrapper'
import CatchAll from 'Router/components/CatchAll'

import Overview from './routes/Overview'
import Transactions from './routes/Transactions'
import TicketTypes from './routes/TicketTypes'
import PromoCodes from './routes/PromoCodes'
import TimeSlots from './routes/TimeSlots'
import TeamMembers from './routes/TeamMembers'
import Settings from './routes/Settings'
import TicketScanner from './routes/TicketScanner'
import { connect } from 'react-redux'

class AuthenticatedRoutes extends Component {
  render() {
    const { match, user } = this.props
    return (
      <Wrapper>
        <Switch>
          {user.isAdmin && (
            <Redirect
              exact
              from={`${match.url}`}
              to={`${match.url}/overview`}
            />
          )}
          {user.isAdmin && (
            <Route exact path={`${match.url}/overview`} component={Overview} />
          )}
          {user.isAdmin && (
            <Route
              exact
              path={`${match.url}/transactions`}
              component={Transactions}
            />
          )}
          {user.isAdmin && (
            <Route
              exact
              path={`${match.url}/ticket-types`}
              component={TicketTypes}
            />
          )}
          {user.isAdmin && (
            <Route
              exact
              path={`${match.url}/promo-codes`}
              component={PromoCodes}
            />
          )}
          {user.isAdmin && (
            <Route
              exact
              path={`${match.url}/time-slots`}
              component={TimeSlots}
            />
          )}
          {user.isAdmin && (
            <Route
              exact
              path={`${match.url}/team-members`}
              component={TeamMembers}
            />
          )}
          {!user.isAdmin && (
            <Redirect
              exact
              from={`${match.url}`}
              to={`${match.url}/ticket-scanner`}
            />
          )}
          <Route
            exact
            path={`${match.url}/ticket-scanner`}
            component={TicketScanner}
          />
          <Route exact path={`${match.url}/settings`} component={Settings} />
          <CatchAll />
        </Switch>
      </Wrapper>
    )
  }
}

export default connect(state => ({
  user: state.auth.user
}))(AuthenticatedRoutes)
