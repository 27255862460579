import React, { Component } from 'react'
import { Select } from 'semantic-ui-react'
import { tz as moment } from 'moment-timezone'

class CompanySignUp extends Component {
  constructor() {
    super()

    this.state = {
      timeZones: [],
      interval: null
    }
  }

  componentDidMount() {
    this.setTimeZones.apply(this)

    this.setState({
      interval: setInterval(this.setTimeZones.bind(this), 1000)
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  setTimeZones() {
    this.setState({
      timeZones: [
        {
          key: 'America/New_York',
          value: 'America/New_York',
          text: `Eastern Time - ${moment('America/New_York').format('hh:mma')}`
        },
        {
          key: 'America/Chicago',
          value: 'America/Chicago',
          text: `Central Time - ${moment('America/Chicago').format('hh:mma')}`
        },
        {
          key: 'America/Denver',
          value: 'America/Denver',
          text: `Mountain Time - ${moment('America/Denver').format('hh:mma')}`
        },
        {
          key: 'America/Phoenix',
          value: 'America/Phoenix',
          text: `Mountain Time (Arizona) - ${moment('America/Phoenix').format(
            'hh:mma'
          )}`
        },
        {
          key: 'America/Los_Angeles',
          value: 'America/Los_Angeles',
          text: `Pacific Time - ${moment('America/Los_Angeles').format(
            'hh:mma'
          )}`
        },
        {
          key: 'America/Anchorage',
          value: 'America/Anchorage',
          text: `Alaska Time - ${moment('America/Anchorage').format('hh:mma')}`
        },
        {
          key: 'America/Adak',
          value: 'America/Adak',
          text: `Aleutian Time - ${moment('America/Adak').format('hh:mma')}`
        },
        {
          key: 'Pacific/Honolulu',
          value: 'Pacific/Honolulu',
          text: `Hawaii Time - ${moment('Pacific/Honolulu').format('hh:mma')}`
        }
      ]
    })
  }

  render() {
    return <Select {...this.props} options={this.state.timeZones} />
  }
}

export default CompanySignUp
