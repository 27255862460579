import React from 'react'
import { Segment } from 'semantic-ui-react'
import styled from 'styled-components'

const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 10px;
  background: #262626;
`

const Box = styled(Segment)`
  margin-top: 20px;
  width: 500px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

const Logo = styled.img`
  width: 350px;
  @media screen and (max-width: 600px) {
    width: 70%;
  }
`

export default props => {
  return (
    <Wrapper>
      <Logo src={process.env.REACT_APP_LOGO_URL} alt="Haunted Ticketing Logo" />
      <Box>{props.children}</Box>
      <p style={{ marginBottom: 3, marginTop: 45, color: '#CCC' }}>
        <a
          href="https://www.hauntedticketing.com/terms-conditions.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms Of Service
        </a>{' '}
        |{' '}
        <a
          href="https://www.hauntedticketing.com/privacy-policy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </p>
      <p style={{ color: '#CCC' }}>
        Haunted Ticketing, LLC &copy; {new Date().getFullYear()}
      </p>
    </Wrapper>
  )
}
