import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const ProtectedRoute = props => {
  window.scrollTo(0, 0)

  if (props.insecure && props.user) {
    const origin = props.location.state ? props.location.state.origin : null
    return <Redirect to={origin || '/dashboard/overview'} />
  } else if (props.secure && !props.user) {
    return (
      <Redirect
        to={{
          pathname: '/authentication/sign-in',
          state: { origin: props.location.pathname }
        }}
      />
    )
  }

  return <Route {...props} />
}

export default connect(state => ({
  user: state.auth.user
}))(ProtectedRoute)
