import React, { Component } from 'react'
import { Modal, Button, Form, Message, Input } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class ResendTicketsModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      error: '',
      customerEmail: props.customerEmail
    }
  }

  resendTickets() {
    if (!this.state.customerEmail) {
      return this.setState({
        error: 'All fields must be completed.'
      })
    }

    this.setState({ loading: true })
    axios()
      .post('/transactions/' + this.props.transactionId + '/email', {
        customerEmail: this.state.customerEmail
      })
      .then(res => {
        this.setState({ loading: false })
        toast.success('Tickets sent.')
        this.props.getTransactions()
        this.props.onClose()
        this.props.closeParent()
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err.message
        })
      })
  }

  render() {
    return (
      <Modal
        size="tiny"
        closeOnDimmerClick={false}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>Resend Tickets...</Modal.Header>

        <Modal.Content>
          {this.state.error && <Message negative>{this.state.error}</Message>}
          <Form>
            <Form.Field>
              <label>Confirm Customer Email</label>
              <Input
                onChange={e => this.setState({ customerEmail: e.target.value })}
                value={this.state.customerEmail}
              />
            </Form.Field>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button
            loading={this.state.loading}
            onClick={this.resendTickets.bind(this)}
            color="blue"
          >
            Resend
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ResendTicketsModal
