import React, { Component } from 'react'
import { Modal, Button, Form, Message } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class RefundModal extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      error: '',
      reason: ''
    }
  }

  rejectRefund() {
    if (!this.state.reason) {
      return this.setState({
        error: 'All fields must be completed.'
      })
    }

    this.setState({ loading: true })
    axios()
      .patch('/transactions/reject-refund/' + this.props.transactionId, {
        reason: this.state.reason
      })
      .then(res => {
        this.setState({ loading: false })
        toast.success('Refund request updated.')
        this.props.getTransactions()
        this.props.onClose()
        this.props.closeParent()
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err.message
        })
      })
  }

  render() {
    return (
      <Modal
        size="tiny"
        closeOnDimmerClick={false}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>Reject Refund...</Modal.Header>

        <Modal.Content>
          {this.state.error && <Message negative>{this.state.error}</Message>}
          <Form>
            <Form.TextArea
              label="Why is this refund request being rejected? This will be seen by the customer."
              value={this.state.reason}
              placeholder="i.e. Refunds must be requested within 5 days of purchasing."
              autoHeight
              rows={2}
              onChange={(e, data) => this.setState({ reason: data.value })}
            />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button
            loading={this.state.loading}
            onClick={this.rejectRefund.bind(this)}
            color="blue"
          >
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default RefundModal
