import React, { Component } from 'react'
import { Segment, Icon, Input, Grid } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import Table from './components/Table'

class Transactions extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      transactions: [],
      currentPage: 1,
      totalPages: 0,
      search: ''
    }
  }

  componentDidMount() {
    this.getTransactions.apply(this)
  }

  getTransactions() {
    this.setState({ loading: true })

    axios()
      .get('/transactions', {
        params: {
          page: this.state.currentPage,
          search: this.state.search || undefined,
          refundRequestsFirst: true
        }
      })
      .then(res => {
        this.setState({
          loading: false,
          transactions: res.results,
          currentPage: res.currentPage,
          totalPages: res.totalPages
        })
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({ loading: false })
      })
  }

  changePage(event, data) {
    if (data.activePage !== this.state.currentPage) {
      this.setState(
        {
          currentPage: data.activePage,
          search: ''
        },
        this.getTransactions.bind(this)
      )
    }
  }

  handleInputChange(event, data) {
    this.setState({
      [event.target.name]: event.target.value || data.value
    })
  }

  keyUpHandle(event) {
    if (event.keyCode === 13) {
      this.search.apply(this)
    }
  }

  search() {
    this.setState({ currentPage: 1 }, this.getTransactions.bind(this))
  }

  render() {
    return (
      <Segment loading={this.state.loading}>
        <Grid stackable>
          <Grid.Column verticalAlign="middle">
            <h2>Transactions</h2>
          </Grid.Column>
          <Grid.Column width={4} floated="right">
            <Input
              icon={
                <Icon name="search" link onClick={this.search.bind(this)} />
              }
              placeholder="Search..."
              name="search"
              fluid
              value={this.state.search}
              onKeyUp={this.keyUpHandle.bind(this)}
              onChange={this.handleInputChange.bind(this)}
            />
          </Grid.Column>
        </Grid>

        <Table
          getTransactions={this.getTransactions.bind(this)}
          transactions={this.state.transactions}
          changePage={this.changePage.bind(this)}
          currentPage={this.state.currentPage}
          totalPages={this.state.totalPages}
        />
      </Segment>
    )
  }
}

export default Transactions
