import React, { Component } from 'react'
import { Modal, Button, Form, Message } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class EditUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      error: '',
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      isAdmin: null,
      disabled: null
    }
  }

  handleInputChange(event, data) {
    this.setState({
      [event.target.name || data.name]:
        event.target.value || data.value || data.checked
    })
  }

  toggleIsAdmin() {
    this.setState({ isAdmin: !this.state.isAdmin })
  }

  save() {
    this.setState({ loading: true })

    axios()
      .patch('/users/' + this.state.id, {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        isAdmin: this.state.isAdmin,
        disabled: this.state.disabled
      })
      .then(res => {
        toast.success('Details saved successfully.')
        this.close.apply(this)
        this.props.getUsers()
      })
      .catch(err => {
        this.setState({ loading: false, error: err.message })
      })
  }

  onOpen() {
    this.setState({
      loading: false,
      id: this.props.user.id,
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      email: this.props.user.email,
      phone: this.props.user.phone,
      isAdmin: this.props.user.isAdmin,
      disabled: this.props.user.disabled
    })
  }

  close() {
    this.setState({ error: '' })
    this.props.onClose()
  }

  render() {
    return (
      <Modal
        size="tiny"
        closeOnDimmerClick={false}
        open={this.props.open}
        onMount={this.onOpen.bind(this)}
        onClose={this.close.bind(this)}
      >
        <Modal.Header>Edit/View Team Member</Modal.Header>
        <Modal.Content>
          {this.state.error && <Message negative>{this.state.error}</Message>}

          <Form>
            <Form.Input
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleInputChange.bind(this)}
              label="First Name"
            />
            <Form.Input
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleInputChange.bind(this)}
              label="Last Name"
            />
            <Form.Input
              name="email"
              value={this.state.email}
              onChange={this.handleInputChange.bind(this)}
              label="Email"
            />
            <Form.Input
              name="phone"
              value={this.state.phone}
              onChange={this.handleInputChange.bind(this)}
              label="Phone"
            />
            <div style={{ position: 'relative' }}>
              <Form.Checkbox
                style={{ position: 'absolute', top: 0, right: 0 }}
                name="disabled"
                onChange={this.handleInputChange.bind(this)}
                checked={this.state.disabled}
                label="Disabled"
              />
              <Form.Radio
                name="isAdmin"
                onChange={this.toggleIsAdmin.bind(this)}
                checked={!this.state.isAdmin}
                label="Ticket Scanner"
              />
              <Form.Radio
                name="isAdmin"
                onChange={this.toggleIsAdmin.bind(this)}
                checked={this.state.isAdmin}
                label="Admin"
              />
            </div>
            <Message
              hidden={
                !this.state.isAdmin ||
                (this.props.user && this.props.user.isAdmin)
              }
              header="Be Careful!"
              content="Giving a user admin privileges will give them full control of the dashboard."
              negative
            />
            <Message
              hidden={
                !this.state.disabled ||
                (this.props.user && this.props.user.disabled)
              }
              header="Be Careful!"
              content="Disabling this user will block them from accessing the dashboard and the ticket scanning app."
              negative
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close.bind(this)}>Cancel</Button>
          <Button
            color="green"
            loading={this.state.loading}
            onClick={this.save.bind(this)}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default EditUser
