import React, { Component } from 'react'
import {
  Segment,
  Grid,
  Statistic,
  Divider,
  Popup,
  Icon
} from 'semantic-ui-react'

class Totals extends Component {
  render() {
    const data = this.props.data
    if (!data) return null
    return (
      <Segment padded>
        <Grid columns="equal" textAlign="center" stackable>
          <Grid.Column>
            <Statistic>
              <Statistic.Value>${data.revenue}</Statistic.Value>
              <Statistic.Label>
                Revenue
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Total revenue during the selected time period. Includes collected sales tax and fees. Excludes any refunds."
                />
              </Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid>
        <Divider />
        <Grid columns="equal" textAlign="center" stackable>
          <Grid.Column>
            <Statistic size="small">
              <Statistic.Value>{data.ticketsSold}</Statistic.Value>
              <Statistic.Label>
                Sold
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Number of tickets sold during the selected time period. Excludes any refunded tickets."
                />
              </Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic size="small">
              <Statistic.Value>{data.ticketsRedeemed}</Statistic.Value>
              <Statistic.Label>
                Redeemed
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Number of tickets redeemed during the selected time period."
                />
              </Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic size="small">
              <Statistic.Value>{data.ticketsRefunded}</Statistic.Value>
              <Statistic.Label>
                Refunded
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Number of tickets refunded during the selected time period."
                />
              </Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid>
      </Segment>
    )
  }
}

export default Totals
