import React, { Component } from 'react'
import {
  Modal,
  Button,
  Message,
  Icon,
  Responsive,
  Form,
  Popup
} from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import DateTime from 'components/DateTime'
import moment from 'moment-timezone'

class EditTimeSlot extends Component {
  constructor() {
    super()

    this.initialState = {
      loading: false,
      error: '',
      startAt: undefined,
      endAt: undefined,
      numberAvailable: '',
      ticketTypeIds: []
    }

    this.state = this.initialState
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.timeSlot) {
      this.setState({
        startAt: moment(nextProps.timeSlot.startAt),
        endAt: moment(nextProps.timeSlot.endAt),
        numberAvailable: nextProps.timeSlot.numberAvailable || '',
        ticketTypeIds: nextProps.timeSlot.ticketTypes.map(tt => tt.id)
      })
    }
  }

  submit() {
    if (!this.state.startAt || !this.state.endAt) {
      return this.setState({
        error: 'Start and end times must be selected.'
      })
    }

    if (this.state.startAt.isAfter(this.state.endAt)) {
      return this.setState({
        error: 'End time must come after start time.'
      })
    }

    this.setState({ loading: true })
    axios()
      .patch(`/time-slots/${this.props.timeSlot.id}`, {
        startAt: this.state.startAt,
        endAt: this.state.endAt,
        numberAvailable: this.state.numberAvailable || null,
        ticketTypeIds: this.state.ticketTypeIds
      })
      .then(res => {
        toast.success('Time slot updated.')
        this.props.getTimeSlots()
        this.close.apply(this)
      })
      .catch(err => {
        this.setState({ loading: false, error: err.message })
      })
  }

  close() {
    this.setState(this.initialState)
    this.props.onClose()
  }

  handleInputChange(event, data) {
    this.setState({
      [event.target.name || data.name]: event.target.value || data.value
    })
  }

  render() {
    return (
      <Modal
        size="small"
        closeOnDimmerClick={false}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>New Time Slot</Modal.Header>
        <Modal.Content>
          {this.state.error && <Message negative>{this.state.error}</Message>}
          <Message warning>
            Changing existing time slots will not notify customers who have
            already purchased tickets of any changes or updates.
          </Message>
          <Form>
            <Form.Group style={{ marginBottom: 0 }}>
              <Form.Field width={7} style={{ marginBottom: '1em' }}>
                <DateTime
                  selectsStart
                  openToDate={this.state.startAt}
                  value={this.state.startAt}
                  startDate={this.state.startAt}
                  endDate={this.state.endAt}
                  highlightTimeSlots
                  onChange={date => this.setState({ startAt: date })}
                  placeholderText="Start Time"
                />
              </Form.Field>
              <Responsive
                style={{ textAlign: 'center' }}
                width={2}
                minWidth="800"
                as={Form.Field}
              >
                <Icon
                  style={{ paddingTop: 5 }}
                  name="long arrow alternate right"
                  size="large"
                />
              </Responsive>
              <Form.Field width={7} style={{ marginBottom: '1em' }}>
                <DateTime
                  selectsEnd
                  value={this.state.endAt}
                  startDate={this.state.startAt}
                  endDate={this.state.endAt}
                  highlightTimeSlots
                  onChange={date => this.setState({ endAt: date })}
                  placeholderText="End Time"
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>
                Valid Ticket Types{' '}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Select the ticket types that will be available during this time slot. Leave blank if you want to make all ticket types available."
                />
              </label>
              <Form.Dropdown
                multiple
                selection
                fluid
                onChange={this.handleInputChange.bind(this)}
                value={this.state.ticketTypeIds}
                name="ticketTypeIds"
                placeholder="(optional)"
                options={this.props.ticketTypes.map(t => ({
                  text: t.name,
                  value: t.id
                }))}
              />
            </Form.Field>

            <Form.Group style={{ marginBottom: 0 }}>
              <Form.Field width={8} style={{ marginBottom: '1em' }}>
                <label>
                  Quantity{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="The number of tickets available for this time slot. Leave blank if you do not want to limit sales."
                  />
                </label>
                <Form.Input
                  value={this.state.numberAvailable}
                  name="numberAvailable"
                  onChange={this.handleInputChange.bind(this)}
                  placeholder="(optional)"
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close.bind(this)}>Cancel</Button>
          <Button
            color="green"
            loading={this.state.loading}
            onClick={this.submit.bind(this)}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default EditTimeSlot
