import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import CompanySettings from './components/CompanySettings'
import UserSettings from './components/UserSettings'
import ChangePassword from './components/ChangePassword'
import BankAccountSettings from './components/BankAccountSettings'
import UploadLogo from './components/UploadLogo'
import TicketPortalSettings from './components/TicketPortalSettings'
import { connect } from 'react-redux'

class Settings extends Component {
  render() {
    const user = this.props.user
    if (!user.isAdmin) return <UserSettings />
    return (
      <Grid stackable reversed="mobile" style={{ marginTop: 0 }}>
        <Grid.Column width="8">
          <CompanySettings />
          <TicketPortalSettings />
        </Grid.Column>
        <Grid.Column width="8">
          <UserSettings />
          <ChangePassword />
          <UploadLogo />
          <BankAccountSettings />
        </Grid.Column>
      </Grid>
    )
  }
}

export default connect(state => ({
  user: state.auth.user
}))(Settings)
