import React, { Component } from 'react'
import moment from 'moment-timezone'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { Input, Dimmer, Loader, Table, Checkbox } from 'semantic-ui-react'
import TimeSlot from 'components/TimeSlot'

class TimeSlotSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      selectedDay: moment(),
      timeSlots: [],
      selectedTimeSlots: props.preselected || [],
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear(),
      days: []
    }
  }

  componentDidMount() {
    this.getTimeSlots.apply(this)
  }

  getTimeSlots() {
    this.setState({ loading: true })
    axios()
      .get('/time-slots/' + this.props.companyId, {
        params: {
          month: this.state.selectedMonth,
          year: this.state.selectedYear,
          includeDisabled: false
        }
      })
      .then(res => {
        const getDaysBetween = (start, end) => {
          let day = start
          const days = []
          while (day.isBetween(start, end, 'day', '[]')) {
            days.push(day)
            day = day.clone().add(1, 'day')
          }
          return days
        }

        const days = res.reduce((arr, timeSlot) => {
          const start = moment(timeSlot.startAt)
          const end = moment(timeSlot.endAt)
          return [...arr, ...getDaysBetween(start, end)]
        }, [])

        this.setState({
          loading: false,
          timeSlots: res,
          days: days
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  handleMonthChange(date) {
    this.setState(
      {
        selectedMonth: date.month() + 1,
        selectedYear: date.year()
      },
      this.getTimeSlots
    )
  }

  handleDateChange(date) {
    if (!date.isSame(this.state.selectedDay, 'month')) {
      this.handleMonthChange(date)
    }
    this.setState({ selectedDay: date })
  }

  addTimeSlot(timeSlot) {
    const selectedTimeSlots = this.state.selectedTimeSlots.concat(timeSlot)
    this.setState({ selectedTimeSlots })
    this.props.onChange(selectedTimeSlots.map(ts => ts.id))
  }

  removeTimeSlot(timeSlot) {
    const selectedTimeSlots = this.state.selectedTimeSlots.filter(
      ts => ts.id !== timeSlot.id
    )
    this.setState({ selectedTimeSlots })
    this.props.onChange(selectedTimeSlots.map(ts => ts.id))
  }

  render() {
    const availableTimeSlots = this.state.timeSlots.filter(timeSlot => {
      return (
        !this.state.selectedTimeSlots.find(ts => ts.id === timeSlot.id) &&
        this.state.selectedDay.isBetween(
          moment(timeSlot.startAt),
          moment(timeSlot.endAt),
          'day',
          '[]'
        )
      )
    })

    return (
      <div style={{ marginTop: 10 }}>
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                <div style={{ width: 180 }}>
                  <DatePicker
                    onMonthChange={this.handleMonthChange.bind(this)}
                    onYearChange={this.handleMonthChange.bind(this)}
                    showMonthDropdown
                    selected={this.state.selectedDay}
                    dateFormat="LL"
                    onChange={this.handleDateChange.bind(this)}
                    useShortMonthInDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    highlightDates={this.state.days}
                    withPortal
                    customInput={<Input fluid icon="calendar" />}
                  >
                    <Dimmer active={this.state.loading} inverted>
                      <Loader />
                    </Dimmer>
                  </DatePicker>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!availableTimeSlots.length &&
              !this.state.selectedTimeSlots.length && (
                <Table.Row>
                  <Table.Cell colSpan="3" textAlign="center">
                    <h4>No time slots found. Select a different day.</h4>
                  </Table.Cell>
                </Table.Row>
              )}

            {this.state.selectedTimeSlots.map(timeSlot => {
              return (
                <Table.Row key={timeSlot.id}>
                  <Table.Cell>
                    <TimeSlot timeSlot={timeSlot} />
                  </Table.Cell>
                  <Table.Cell textAlign="right" collapsing>
                    <Checkbox
                      onClick={() => this.removeTimeSlot(timeSlot)}
                      checked
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}

            {availableTimeSlots.map(timeSlot => {
              return (
                <Table.Row key={timeSlot.id}>
                  <Table.Cell>
                    <TimeSlot timeSlot={timeSlot} />
                  </Table.Cell>
                  <Table.Cell textAlign="right" collapsing>
                    <Checkbox
                      style={{ float: 'right' }}
                      onClick={() => this.addTimeSlot(timeSlot)}
                      checked={false}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default connect(state => ({
  companyId: state.auth.company.id
}))(TimeSlotSelect)
