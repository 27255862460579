import React, { Component } from 'react'
import { Message, Form, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { updateUser, updateCompany } from 'ducks/auth'
import { connect } from 'react-redux'
import axios from 'helpers/axios.helper'

class SignIn extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      error: '',
      password: '',
      email: ''
    }
  }

  handleInputChange(prop, event) {
    this.setState({ [prop]: event.target.value })
  }

  handleSubmit() {
    if (this.state.loading) return
    if (!this.state.password || !this.state.email) {
      return this.setState({ error: 'All fields must be completed.' })
    }
    this.setState({ loading: true })
    axios()
      .post('/authentication/sign-in', {
        email: this.state.email,
        password: this.state.password
      })
      .then(data => {
        this.props.updateCompany(data.company)
        this.props.updateUser(data.user)
        if (!data.user.isAdmin) {
          return this.props.history.push('/dashboard/ticket-scanner')
        }
      })
      .catch(err => {
        this.setState({ error: err.message, loading: false })
      })
  }

  render() {
    return (
      <div>
        <h2 style={{ marginTop: 5 }}>Sign in to get started...</h2>
        {this.state.error && (
          <Message
            negative
            header="Oops! Something went wrong."
            content={this.state.error}
          />
        )}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Input
            label="Email"
            icon="user"
            iconPosition="left"
            onChange={this.handleInputChange.bind(this, 'email')}
            value={this.state.email}
          />
          <Form.Input
            label="Password"
            icon="lock"
            iconPosition="left"
            type="password"
            onChange={this.handleInputChange.bind(this, 'password')}
            value={this.state.password}
          />
          <p style={{ textAlign: 'right', marginTop: '-10px' }}>
            <Link to="/authentication/forgot-password">
              Forgot your password?
            </Link>
          </p>
          <Button
            loading={this.state.loading}
            fluid
            size="large"
            labelPosition="right"
            icon="right chevron"
            color="blue"
            content="Sign In"
          />
        </Form>
        <p style={{ marginTop: 10, textAlign: 'center' }}>
          Don&#39;t have an account?{' '}
          <Link to="/authentication/sign-up">Sign up</Link>
        </p>
      </div>
    )
  }
}

export default connect(null, {
  updateUser,
  updateCompany
})(SignIn)
