import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import { Route } from 'react-router-dom'

export default props => {
  return (
    <Route
      path="*"
      component={props => (
        <Modal open size="small">
          <Header icon="exclamation" content="404 Page Not Found" />
          <Modal.Content>
            <p>Uh oh, looks like you might be lost...</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" onClick={() => props.history.push('/')}>
              <Icon name="home" /> Go Home
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    />
  )
}
