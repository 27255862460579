import React, { Component } from 'react'
import {
  Modal,
  Button,
  Form,
  Popup,
  Icon,
  Table,
  Message,
  Label
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import TimeSlot from 'components/TimeSlot'

class EditPromoCode extends Component {
  render() {
    const promoCode = this.props.selected
    if (!promoCode) return null
    const ticketTypes = promoCode.ticketTypes.length
      ? promoCode.ticketTypes
      : this.props.ticketTypes
    return (
      <Modal
        size="tiny"
        closeOnDimmerClick={false}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>Promo Code</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Button
              label="Promo Code"
              fluid
              style={{ cursor: 'default' }}
              content={promoCode.code}
            />
            <Form.Group style={{ marginBottom: 0 }}>
              <Form.Button
                label="Discount"
                fluid
                width={6}
                style={{ cursor: 'default', marginBottom: '1em' }}
                content={
                  promoCode.discountPercentage
                    ? `${promoCode.discountPercentage}%`
                    : `$${promoCode.discountAmount.toFixed(2)}`
                }
              />
              <Form.Field width={10}>
                <label>
                  Purchase By{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Date that the promo code must be used by."
                  />
                </label>
                <Form.Button
                  fluid
                  style={{ cursor: 'default', marginBottom: '1em' }}
                  content={
                    promoCode.expiresAt
                      ? moment(promoCode.expiresAt).format('LLL')
                      : 'N/A'
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>
                Valid Ticket Types{' '}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Ticket types that can be used with this promo code."
                />
              </label>
              {ticketTypes.map(tt => (
                <Label key={tt.id}>{tt.name}</Label>
              ))}
            </Form.Field>
            {this.props.company.usesTimedTicketing && (
              <Form.Field>
                <label>
                  Valid Time Slots{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Time slots that can be used with this promo code."
                  />
                </label>
                {promoCode.timeSlots.length ? (
                  <Table>
                    <Table.Body>
                      {promoCode.timeSlots.map(timeSlot => (
                        <Table.Row key={timeSlot.id}>
                          <Table.Cell>
                            <TimeSlot timeSlot={timeSlot} />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                ) : (
                  <Message size="small">
                    Promo code will be available for all time slots.
                  </Message>
                )}
              </Form.Field>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.onClose}>Done</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default connect(state => ({
  company: state.auth.company
}))(EditPromoCode)
