const UPDATE = 'notifications/SHOW_ACCOUNT_MODAL'

const initialState = {
  hasTicketTypes: false
}

// REDUCER
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

// ACTIONS
export const updateNotificationsState = values => {
  return {
    type: UPDATE,
    payload: values
  }
}
