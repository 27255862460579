import React, { Component } from 'react'
import { Modal, Button, Form, Message } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class InviteUser extends Component {
  constructor() {
    super()

    this.initialState = {
      loading: false,
      error: '',
      isAdmin: false,
      email: ''
    }

    this.state = this.initialState
  }

  handleInputChange(event, data) {
    this.setState({ [event.target.name]: event.target.value || data.value })
  }

  toggleIsAdmin() {
    this.setState({ isAdmin: !this.state.isAdmin })
  }

  inviteUser() {
    this.setState({ loading: true })

    axios()
      .post('/users/invite-user', {
        email: this.state.email,
        isAdmin: this.state.isAdmin
      })
      .then(res => {
        toast.success(res.message)
        this.close.apply(this)
      })
      .catch(err => {
        this.setState({ loading: false, error: err.message })
      })
  }

  close() {
    this.setState(this.initialState)
    this.props.onClose()
  }

  render() {
    return (
      <Modal
        size="tiny"
        closeOnDimmerClick={false}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>Invite a Team Member</Modal.Header>
        <Modal.Content>
          {this.state.error && <Message negative>{this.state.error}</Message>}

          <Form>
            <Form.Input
              name="email"
              value={this.state.email}
              onChange={this.handleInputChange.bind(this)}
              label="Email"
            />
            <Form.Radio
              name="isAdmin"
              onChange={this.toggleIsAdmin.bind(this)}
              checked={!this.state.isAdmin}
              label="Ticket Scanner"
            />
            <Form.Radio
              name="isAdmin"
              onChange={this.toggleIsAdmin.bind(this)}
              checked={this.state.isAdmin}
              label="Admin"
            />
            <Message
              hidden={!this.state.isAdmin}
              header="Be Careful!"
              content="Giving a user admin privileges will give them full control of the dashboard."
              negative
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close.bind(this)}>Cancel</Button>
          <Button
            color="blue"
            loading={this.state.loading}
            onClick={this.inviteUser.bind(this)}
          >
            Send Invite
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default InviteUser
