import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Segment, Button, Dimmer, Loader, Grid } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import NewTicketType from './components/NewTicketType'
import Table from './components/Table'
import { toast } from 'react-toastify'
import { updateNotificationsState } from 'ducks/notifications'

class TicketTypes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      ticketTypes: [],
      newTicketTypeModal: false,
      includeDisabled: false
    }
  }

  componentDidMount() {
    this.getTicketTypes()
  }

  getTicketTypes() {
    this.setState({ loading: true })
    axios()
      .get('/ticket-types/' + this.props.companyId, {
        params: {
          includeDisabled: this.state.includeDisabled
        }
      })
      .then(res => {
        this.setState({
          loading: false,
          ticketTypes: res
        })
        this.props.updateNotificationsState({
          hasTicketTypes: res.filter(t => !t.disabled).length
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  toggleShowDeleted() {
    this.setState(
      {
        includeDisabled: !this.state.includeDisabled
      },
      this.getTicketTypes
    )
  }

  render() {
    return (
      <Segment>
        <h2>Ticket Types</h2>

        <NewTicketType
          open={this.state.newTicketTypeModal}
          getTicketTypes={this.getTicketTypes.bind(this)}
          onClose={() => this.setState({ newTicketTypeModal: false })}
        />

        <Dimmer inverted active={this.state.loading}>
          <Loader />
        </Dimmer>

        <Grid stackable reversed="mobile" padded={false}>
          <Grid.Column computer={3} tablet={4}>
            <Button
              fluid
              color="red"
              onClick={this.toggleShowDeleted.bind(this)}
              content={
                this.state.includeDisabled ? 'Hide Deleted' : 'Show Deleted'
              }
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4} floated="right">
            <Button
              fluid
              color="blue"
              icon="add"
              onClick={() => this.setState({ newTicketTypeModal: true })}
              content="New Ticket Type"
            />
          </Grid.Column>
        </Grid>

        <Table
          ticketTypes={this.state.ticketTypes}
          getTicketTypes={this.getTicketTypes.bind(this)}
        />
      </Segment>
    )
  }
}

export default connect(
  state => ({
    companyId: state.auth.company.id
  }),
  {
    updateNotificationsState
  }
)(TicketTypes)
