import React, { Component } from 'react'
import { Form, Button, Input, Message, Progress } from 'semantic-ui-react'
import { tz as moment } from 'moment-timezone'
import GoogleAddress from './components/GoogleAddress'
import axios from 'helpers/axios.helper'
import { Link } from 'react-router-dom'
import TimeZones from 'components/TimeZones'
import states from 'helpers/states.json'
import styled from 'styled-components'

const GoogleAddressWrapper = styled.div`
  position: relative;
`
const EnterManually = styled.a`
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 0.92857143em;
  cursor: pointer;
`

class CompanySignUp extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      name: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      website: '',
      timeZone: moment.guess(),
      error: '',
      enterManually: false
    }
  }

  handleInputChange(prop, event, data) {
    this.setState({ [prop]: event.target.value || data.value })
  }

  updateAddress(address) {
    this.setState(address)
  }

  handleSubmit() {
    if (this.state.loading) return
    if (
      !this.state.name ||
      !this.state.address ||
      !this.state.city ||
      !this.state.state ||
      !this.state.zipCode ||
      !this.state.website
    ) {
      return this.setState({ error: 'All fields must be completed.' })
    }
    this.setState({ loading: true })
    axios()
      .post('/authentication/sign-up/company', {
        name: this.state.name,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zipCode: this.state.zipCode,
        website: 'http://' + this.state.website,
        timeZone: this.state.timeZone
      })
      .then(data => {
        this.props.history.push('/authentication/sign-up/user', {
          token: data.token
        })
      })
      .catch(err => {
        this.setState({ error: err.message, loading: false })
      })
  }

  render() {
    return (
      <div>
        <Progress value="1" total="2" progress="ratio" color="green" />
        <h2>Tell us a little about your company...</h2>
        {this.state.error && (
          <Message
            negative
            header="Oops! Something went wrong."
            content={this.state.error}
          />
        )}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Input
            label="Company Name"
            onChange={this.handleInputChange.bind(this, 'name')}
            value={this.state.name}
          />
          {!this.state.enterManually && (
            <GoogleAddressWrapper>
              <Form.Field label="Address" style={{ marginBottom: 0 }} />
              <EnterManually
                onClick={() => this.setState({ enterManually: true })}
              >
                Enter Manually
              </EnterManually>
              <GoogleAddress onChange={this.updateAddress.bind(this)} />
            </GoogleAddressWrapper>
          )}
          {this.state.enterManually && (
            <React.Fragment>
              <Form.Input
                label="Address"
                value={this.state.address}
                onChange={this.handleInputChange.bind(this, 'address')}
              />
              <Form.Group widths="equal">
                <Form.Input
                  label="City"
                  fluid
                  value={this.state.city}
                  onChange={this.handleInputChange.bind(this, 'city')}
                />
                <Form.Dropdown
                  label="State"
                  fluid
                  search
                  selection
                  options={states}
                  value={this.state.state}
                  searchInput={{ autoComplete: 'nope' }}
                  onChange={this.handleInputChange.bind(this, 'state')}
                />
                <Form.Input
                  label="Zip Code"
                  fluid
                  value={this.state.zipCode}
                  onChange={this.handleInputChange.bind(this, 'zipCode')}
                />
              </Form.Group>
            </React.Fragment>
          )}
          <Form.Field>
            <label>Website</label>
            <Input
              label="http://"
              onChange={this.handleInputChange.bind(this, 'website')}
              value={this.state.website}
            />
          </Form.Field>
          <Form.Field>
            <label>Time Zone</label>
            <TimeZones
              value={this.state.timeZone}
              onChange={this.handleInputChange.bind(this, 'timeZone')}
            />
          </Form.Field>
          <Button
            loading={this.state.loading}
            fluid
            size="large"
            labelPosition="right"
            icon="right chevron"
            color="blue"
            content="Next"
          />
        </Form>
        <p style={{ marginTop: 10, textAlign: 'center' }}>
          Already have an account?{' '}
          <Link to="/authentication/sign-in">Log in</Link>
        </p>
      </div>
    )
  }
}

export default CompanySignUp
