import React, { Component } from 'react'
import { Segment, Button, Grid } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import Table from './components/Table'
import NewPromoCode from './components/NewPromoCode'
import GiveawayGenerator from './components/GiveawayGenerator'
import { connect } from 'react-redux'

class PromoCodes extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      promoCodes: [],
      currentPage: 1,
      totalPages: 0,
      includeDisabled: false,
      newPromoCodeModal: false,
      giveawayModal: false,
      ticketTypes: []
    }
  }

  componentDidMount() {
    this.getPromoCodes.apply(this)

    axios()
      .get('/ticket-types/' + this.props.companyId)
      .then(res => {
        this.setState({ ticketTypes: res })
      })
      .catch(err => {
        toast.error(err.message)
      })
  }

  getPromoCodes() {
    this.setState({ loading: true })

    axios()
      .get('/promo-codes', {
        params: {
          page: this.state.currentPage,
          includeDisabled: this.state.includeDisabled
        }
      })
      .then(res => {
        this.setState({
          loading: false,
          promoCodes: res.results,
          currentPage: res.currentPage,
          totalPages: res.totalPages
        })
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({ loading: false })
      })
  }

  changePage(event, data) {
    if (data.activePage !== this.state.currentPage) {
      this.setState(
        {
          currentPage: data.activePage
        },
        this.getPromoCodes.bind(this)
      )
    }
  }

  toggleDeleted() {
    this.setState(
      {
        includeDisabled: !this.state.includeDisabled,
        currentPage: 1
      },
      this.getPromoCodes.bind(this)
    )
  }

  render() {
    return (
      <Segment loading={this.state.loading}>
        <h2>Promo Codes</h2>

        <NewPromoCode
          ticketTypes={this.state.ticketTypes}
          open={this.state.newPromoCodeModal}
          getPromoCodes={this.getPromoCodes.bind(this)}
          onClose={() => this.setState({ newPromoCodeModal: false })}
        />

        <GiveawayGenerator
          ticketTypes={this.state.ticketTypes}
          open={this.state.giveawayModal}
          onClose={() => this.setState({ giveawayModal: false })}
        />

        <Grid stackable reversed="mobile" padded={false}>
          <Grid.Column computer={3} tablet={4}>
            <Button
              fluid
              color="red"
              onClick={this.toggleDeleted.bind(this)}
              content={
                this.state.includeDisabled ? 'Hide Deleted' : 'Show Deleted'
              }
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4} floated="right">
            <Button
              fluid
              color="green"
              icon="certificate"
              onClick={() => this.setState({ giveawayModal: true })}
              content="Ticket Giveaways"
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4}>
            <Button
              fluid
              color="blue"
              icon="add"
              onClick={() => this.setState({ newPromoCodeModal: true })}
              content="New Promo Code"
            />
          </Grid.Column>
        </Grid>
        <Table
          ticketTypes={this.state.ticketTypes}
          promoCodes={this.state.promoCodes}
          getPromoCodes={this.getPromoCodes.bind(this)}
          changePage={this.changePage.bind(this)}
          currentPage={this.state.currentPage}
          totalPages={this.state.totalPages}
        />
      </Segment>
    )
  }
}

export default connect(state => ({
  companyId: state.auth.company.id
}))(PromoCodes)
