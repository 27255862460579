import React, { Component } from 'react'
import {
  Modal,
  Button,
  Message,
  Form,
  Popup,
  Icon,
  Checkbox
} from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import DateTime from 'components/DateTime'
import TimeSlotSelect from '../TimeSlotSelect'
import { connect } from 'react-redux'

class GiveAwayGenerator extends Component {
  constructor() {
    super()

    this.initialState = {
      loading: false,
      error: '',
      email: '',
      quantity: '',
      numberOfTickets: 1,
      expiresAt: undefined,
      timeSlotIds: [],
      ticketTypeIds: [],
      showTimeSlotSelect: false
    }

    this.state = this.initialState
  }

  handleInputChange(event, data) {
    this.setState({
      [event.target.name || data.name]: event.target.value || data.value
    })
  }

  toggleTimeSlotSelect(e) {
    e.preventDefault()
    this.setState({
      showTimeSlotSelect: !this.state.showTimeSlotSelect,
      timeSlotIds: []
    })
  }

  submit() {
    if (
      !this.state.email ||
      !this.state.quantity ||
      !this.state.numberOfTickets
    ) {
      return this.setState({
        error: 'Code and discount must be defined.'
      })
    }

    if (this.state.quantity > 100) {
      return this.setState({
        error: 'A maximum of 100 giveaway codes can be made at a time.'
      })
    }

    this.setState({ loading: true })
    axios()
      .post('/promo-codes/giveaways', {
        email: this.state.email,
        quantity: this.state.quantity,
        numberOfTickets: this.state.numberOfTickets,
        expiresAt: this.state.expiresAt,
        timeSlotIds: this.state.timeSlotIds,
        ticketTypeIds: this.state.ticketTypeIds
      })
      .then(res => {
        toast.success(
          'Giveaway codes sent successfully. Please check your email.'
        )
        this.close.apply(this)
      })
      .catch(err => {
        this.setState({ loading: false, error: err.message })
      })
  }

  close() {
    this.setState(this.initialState)
    this.props.onClose()
  }

  render() {
    return (
      <Modal
        size="tiny"
        closeOnDimmerClick={false}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>Giveaway Code Generator</Modal.Header>
        <Modal.Content>
          {this.state.error && <Message negative>{this.state.error}</Message>}

          <Form>
            <Form.Field>
              <label>
                Email{' '}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Email to send the giveaway codes to."
                />
              </label>
              <Form.Input
                onChange={this.handleInputChange.bind(this)}
                value={this.state.email}
                placeholder="Your Email"
                name="email"
              />
            </Form.Field>
            <Form.Group style={{ marginBottom: 0 }}>
              <Form.Field width={8} style={{ marginBottom: '1em' }}>
                <label>
                  Quantity{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Number of codes to generate."
                  />
                </label>
                <Form.Input
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.quantity}
                  placeholder="100 max"
                  name="quantity"
                />
              </Form.Field>
              <Form.Field width={8} style={{ marginBottom: '1em' }}>
                <label>
                  Tickets Per Code{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Number of tickets (usually one) that can be purchased with a single code."
                  />
                </label>
                <Form.Input
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.numberOfTickets}
                  name="numberOfTickets"
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>
                Purchase By{' '}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Date that the promo code must be used by."
                />
              </label>
              <DateTime
                onChange={date => this.setState({ expiresAt: date })}
                placeholderText="(optional)"
                value={this.state.expiresAt}
              />
            </Form.Field>
            <Form.Field>
              <label>
                Valid Ticket Types{' '}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Select the ticket types that can be used with this giveaway code. Leave blank if you want to make all ticket types available."
                />
              </label>
              <Form.Dropdown
                multiple
                selection
                fluid
                onChange={this.handleInputChange.bind(this)}
                value={this.state.ticketTypeIds}
                name="ticketTypeIds"
                placeholder="(optional)"
                options={this.props.ticketTypes.map(t => ({
                  text: t.name,
                  value: t.id
                }))}
              />
            </Form.Field>
            {this.props.company.usesTimedTicketing && (
              <Form.Field>
                <label>
                  Valid Time Slots{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Select the time slots that can be used with this giveaway code. Leave blank if you want to make all time slots available."
                  />
                  <Checkbox
                    size="tiny"
                    toggle
                    checked={this.state.showTimeSlotSelect}
                    style={{ float: 'right' }}
                    onClick={this.toggleTimeSlotSelect.bind(this)}
                  />
                </label>
                {this.state.showTimeSlotSelect ? (
                  <TimeSlotSelect
                    onChange={timeSlotIds => this.setState({ timeSlotIds })}
                  />
                ) : (
                  <Message size="small">
                    Giveaway tickets will be usable during any time slot.{' '}
                    <Button onClick={this.toggleTimeSlotSelect.bind(this)}>
                      Limit the giveaway tickets to specific time slots.
                    </Button>
                  </Message>
                )}
              </Form.Field>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close.bind(this)}>Cancel</Button>
          <Button
            color="green"
            loading={this.state.loading}
            onClick={this.submit.bind(this)}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default connect(state => ({
  company: state.auth.company
}))(GiveAwayGenerator)
