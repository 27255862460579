import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input, Dropdown, Dimmer, Loader, Icon } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import timeOptions from './timeOptions'
import moment from 'moment-timezone'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class DateTime extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      time: props.value ? props.value.format('H:mm') : '0:00',
      date: props.value,
      highlighted: []
    }
  }

  componentDidMount() {
    if (this.props.highlightTimeSlots) {
      this.handleMonthChange(this.state.date)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hasOwnProperty('value')) {
      this.setState({
        date: nextProps.value,
        time: nextProps.value ? nextProps.value.format('H:mm') : '0:00'
      })
    }
  }

  handleMonthChange(date) {
    if (!this.props.highlightTimeSlots || !date) return
    this.setState({ loading: true })
    axios()
      .get('/time-slots/' + this.props.company.id, {
        params: {
          month: date.month() + 1,
          year: date.year(),
          includeDisabled: false
        }
      })
      .then(res => {
        const getDaysBetween = (start, end) => {
          let day = start
          const days = []
          while (day.isBetween(start, end, 'day', '[]')) {
            days.push(day)
            day = day.clone().add(1, 'day')
          }
          return days
        }

        const days = res.reduce((arr, timeSlot) => {
          const start = moment(timeSlot.startAt)
          const end = moment(timeSlot.endAt)
          return [...arr, ...getDaysBetween(start, end)]
        }, [])

        this.setState({
          loading: false,
          highlighted: days
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  handleTimeChange(event, data) {
    if (!this.state.date) {
      return this.setState({ time: data.value })
    }

    this.props.onChange(
      this.state.date
        .hour(data.value.split(':')[0])
        .minute(data.value.split(':')[1])
    )
  }

  handleDateChange(date) {
    this.props.onChange(
      date
        .hour(this.state.time.split(':')[0])
        .minute(this.state.time.split(':')[1])
    )
  }

  render() {
    return (
      <DatePicker
        {...this.props}
        value={undefined}
        showMonthDropdown
        dateFormat="LL"
        useShortMonthInDropdown
        showYearDropdown
        scrollableYearDropdown
        withPortal
        selected={this.state.date}
        onChange={this.handleDateChange.bind(this)}
        highlightDates={this.state.highlighted}
        onMonthChange={this.handleMonthChange.bind(this)}
        onYearChange={this.handleMonthChange.bind(this)}
        customInput={
          <Input
            fluid
            icon={
              <Icon
                style={{
                  cursor: 'pointer',
                  pointerEvents: 'auto'
                }}
                onClick={() => this.props.onChange(undefined)}
                name="times circle"
              />
            }
            iconPosition="left"
            label={{
              style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
              content: (
                <Dropdown
                  {...this.props.dropdown}
                  value={this.state.time}
                  onChange={this.handleTimeChange.bind(this)}
                  style={{ width: 75 }}
                  options={timeOptions}
                  scrolling
                />
              )
            }}
            labelPosition="right"
          />
        }
      >
        <Dimmer active={this.state.loading} inverted>
          <Loader />
        </Dimmer>
      </DatePicker>
    )
  }
}

export default connect(state => ({
  company: state.auth.company
}))(DateTime)
