import React, { useCallback, useState } from 'react'
import { Button, Divider, Dropdown, Segment } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import Scanner from './components/Scanner.component'
import TransactionModal from './components/Transaction.modal'
import moment from 'moment-timezone'

const TicketScanner = () => {
  const [scanning, setScanning] = useState(false)
  const [transaction, setTransaction] = useState()
  const [searchResults, setSearchResults] = useState([])

  const fetchTransaction = useCallback(ticketId => {
    if (!ticketId) return toast.error('No tickets found.')

    setScanning(false)
    if (navigator.vibrate) {
      navigator.vibrate(100)
    }

    axios()
      .get('/transactions/' + ticketId)
      .then(res => setTransaction(res))
      .catch(err => toast.error(err.message))
  }, [])

  const searchTransactions = (event, data) => {
    if (!data.searchQuery) return
    axios()
      .get('/transactions', {
        params: {
          page: 1,
          search: data.searchQuery
        }
      })
      .then(res => setSearchResults(res.results))
      .catch(err => toast.error(err.message))
  }

  return (
    <Segment>
      <h2>Ticket Scanner</h2>
      <Dropdown
        placeholder="Search By Email"
        search
        fluid
        selection
        onSearchChange={searchTransactions}
        onChange={(res, data) => fetchTransaction(data.value)}
        options={searchResults.map(transaction => ({
          key: transaction.id,
          text: `${transaction.customerEmail} - ${moment(
            transaction.purchasedAt
          ).format('ll')}`,
          value: transaction.tickets?.[0]?.id
        }))}
      />

      <Divider />

      <Button
        positive={!scanning}
        negative={scanning}
        fluid
        onClick={() => setScanning(!scanning)}
      >
        {scanning ? 'Stop' : 'Start'} Scanning
      </Button>

      <Scanner scanning={scanning} onScanSuccess={fetchTransaction} />

      <TransactionModal
        open={Boolean(transaction)}
        transaction={transaction}
        onClose={() => setTransaction(null)}
      />
    </Segment>
  )
}

export default TicketScanner
