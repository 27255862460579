import React, { Component } from 'react'
import {
  Modal,
  Button,
  Message,
  Form,
  Popup,
  Icon,
  Checkbox,
  Dropdown,
  Input
} from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import DateTime from 'components/DateTime'
import TimeSlotSelect from '../TimeSlotSelect'
import { connect } from 'react-redux'

class NewPromoCode extends Component {
  constructor() {
    super()

    this.initialState = {
      loading: false,
      error: '',
      code: '',
      discountType: '$',
      discount: '',
      expiresAt: undefined,
      timeSlotIds: [],
      ticketTypeIds: [],
      showTimeSlotSelect: false
    }

    this.state = this.initialState
  }

  handleInputChange(event, data) {
    this.setState({
      [event.target.name || data.name]: event.target.value || data.value
    })
  }

  toggleTimeSlotSelect(e) {
    e.preventDefault()
    this.setState({
      showTimeSlotSelect: !this.state.showTimeSlotSelect,
      timeSlotIds: []
    })
  }

  submit() {
    if (!this.state.code || !this.state.discount) {
      return this.setState({
        error: 'Code and discount must be defined.'
      })
    }

    this.setState({ loading: true })
    axios()
      .post('/promo-codes', {
        code: this.state.code,
        discountPercentage:
          this.state.discountType === '%' ? this.state.discount : 0,
        discountAmount:
          this.state.discountType === '$' ? this.state.discount : 0,
        expiresAt: this.state.expiresAt,
        timeSlotIds: this.state.timeSlotIds,
        ticketTypeIds: this.state.ticketTypeIds
      })
      .then(res => {
        toast.success('Promo code created.')
        this.close.apply(this)
        this.props.getPromoCodes()
      })
      .catch(err => {
        this.setState({ loading: false, error: err.message })
      })
  }

  close() {
    this.setState(this.initialState)
    this.props.onClose()
  }

  render() {
    return (
      <Modal
        size="tiny"
        closeOnDimmerClick={false}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>New Promo Code</Modal.Header>
        <Modal.Content>
          {this.state.error && <Message negative>{this.state.error}</Message>}

          <Form>
            <Form.Field>
              <label>
                Promo Code{' '}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Must be all letters and numbers with no spaces."
                />
              </label>
              <Form.Input
                maxLength="15"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.code}
                placeholder="i.e. SAVE20, FACEBOOK"
                name="code"
              />
            </Form.Field>
            <Form.Group style={{ marginBottom: 0 }}>
              <Form.Field width={6} style={{ marginBottom: '1em' }}>
                <label>Discount</label>
                <Input
                  label={
                    <Dropdown
                      value={this.state.discountType}
                      onChange={this.handleInputChange.bind(this)}
                      name="discountType"
                      options={[
                        { text: '$', value: '$' },
                        { text: '%', value: '%' }
                      ]}
                    />
                  }
                  labelPosition="right"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.discount}
                  placeholder="2"
                  name="discount"
                />
              </Form.Field>

              <Form.Field width={10}>
                <label>
                  Purchase By{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Date that the promo code must be used by."
                  />
                </label>
                <DateTime
                  onChange={date => this.setState({ expiresAt: date })}
                  placeholderText="(optional)"
                  value={this.state.expiresAt}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>
                Valid Ticket Types{' '}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Select the ticket types that can be used with this promo code. Leave blank if you want to make all ticket types available."
                />
              </label>
              <Form.Dropdown
                multiple
                selection
                fluid
                onChange={this.handleInputChange.bind(this)}
                value={this.state.ticketTypeIds}
                name="ticketTypeIds"
                placeholder="(optional)"
                options={this.props.ticketTypes.map(t => ({
                  text: t.name,
                  value: t.id
                }))}
              />
            </Form.Field>
            {this.props.company.usesTimedTicketing && (
              <Form.Field>
                <label>
                  Valid Time Slots{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Select the time slots that can be used with this promo code. Leave blank if you want to make all time slots available."
                  />
                  <Checkbox
                    size="tiny"
                    toggle
                    checked={this.state.showTimeSlotSelect}
                    style={{ float: 'right' }}
                    onClick={this.toggleTimeSlotSelect.bind(this)}
                  />
                </label>
                {this.state.showTimeSlotSelect ? (
                  <TimeSlotSelect
                    onChange={timeSlotIds => this.setState({ timeSlotIds })}
                  />
                ) : (
                  <Message size="small">
                    Promo code will be available for all time slots.{' '}
                    <Button onClick={this.toggleTimeSlotSelect.bind(this)}>
                      Limit the promo code to specific time slots.
                    </Button>
                  </Message>
                )}
              </Form.Field>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close.bind(this)}>Cancel</Button>
          <Button
            color="green"
            loading={this.state.loading}
            onClick={this.submit.bind(this)}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default connect(state => ({
  company: state.auth.company
}))(NewPromoCode)
