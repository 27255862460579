import React, { Component } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import Wrapper from './components/Wrapper'
import CatchAll from 'Router/components/CatchAll'

import CompanySignUp from './routes/CompanySignUp'
import UserSignUp from './routes/UserSignUp'
import SignIn from './routes/SignIn'
import ForgotPassword from './routes/ForgotPassword'
import ResetPassword from './routes/ResetPassword'
import AcceptInvitation from './routes/AcceptInvitation'

class UnauthenticatedRoutes extends Component {
  render() {
    const { match } = this.props
    return (
      <Wrapper>
        <Switch>
          <Redirect exact from={match.url} to={`${match.url}/sign-in`} />
          <Route exact path={`${match.url}/sign-in`} component={SignIn} />
          <Redirect
            exact
            from={`${match.url}/sign-up`}
            to={`${match.url}/sign-up/company`}
          />
          <Route
            exact
            path={`${match.url}/sign-up/company`}
            component={CompanySignUp}
          />
          <Route
            exact
            path={`${match.url}/sign-up/user`}
            component={UserSignUp}
          />
          <Route
            exact
            path={`${match.url}/forgot-password`}
            component={ForgotPassword}
          />
          <Route
            exact
            path={`${match.url}/reset-password/:token`}
            component={ResetPassword}
          />
          <Route
            exact
            path={`${match.url}/accept-invitation/:token`}
            component={AcceptInvitation}
          />
          <CatchAll />
        </Switch>
      </Wrapper>
    )
  }
}

export default UnauthenticatedRoutes
