import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from './helpers/axios.helper'
import {
  updateCompany,
  updateUser,
  clearUser,
  clearCompany
} from './ducks/auth'
import { Dimmer, Loader } from 'semantic-ui-react'

class SignInWrapper extends Component {
  constructor() {
    super()

    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    if (!window.localStorage.getItem('token')) return

    this.setState({ loading: true })
    axios()
      .get('/authentication/me')
      .then(data => {
        this.props.updateCompany(data.company)
        this.props.updateUser(data.user)
        this.setState({ loading: false })
      })
      .catch(err => {
        this.props.clearUser()
        this.props.clearCompany()
        console.log('Token Error:', err.message)
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <div>
        <Dimmer active={this.state.loading}>
          <Loader />
        </Dimmer>
        {this.props.children}
      </div>
    )
  }
}

export default connect(null, {
  updateUser,
  updateCompany,
  clearUser,
  clearCompany
})(SignInWrapper)
