import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Confirm, Button } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { updateCompany } from 'ducks/auth'

class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      confirm: false
    }
  }

  disableTimedTicketing() {
    this.setState({ loading: true })
    axios()
      .patch('/companies', {
        usesTimedTicketing: false
      })
      .then(res => {
        this.setState({ loading: false, confirm: false })
        this.props.updateCompany(res)
      })
      .catch(err => {
        this.setState({ loading: false, confirm: false })
        toast.error(err.message)
      })
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: window.innerWidth < 500 ? 'center' : 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <Confirm
          onCancel={() => this.setState({ confirm: false })}
          onConfirm={this.disableTimedTicketing.bind(this)}
          size="tiny"
          content="Are you sure you want to disable timed ticketing?"
          header="Are you sure?"
          confirmButton={{
            loading: this.state.loading,
            content: 'Yep!'
          }}
          open={this.state.confirm}
        />

        <p style={{ marginBottom: 5 }}>
          <Button onClick={() => this.setState({ confirm: true })}>
            <strong>Stop using timed ticketing.</strong>
          </Button>
        </p>
        <p>
          <Link to="/dashboard/settings">
            <strong>{this.props.company.timeZone} Timezone</strong>
          </Link>
        </p>
      </div>
    )
  }
}

export default connect(
  state => ({
    company: state.auth.company
  }),
  {
    updateCompany
  }
)(Footer)
