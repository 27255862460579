import React, { Component } from 'react'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import CatchAll from './components/CatchAll'

import AuthenticatedRoutes from '../AuthenticatedRoutes'
import UnauthenticatedRoutes from '../UnauthenticatedRoutes'

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <ProtectedRoute
            secure
            path="/dashboard"
            component={AuthenticatedRoutes}
          />
          <ProtectedRoute
            insecure
            path="/authentication"
            component={UnauthenticatedRoutes}
          />
          <CatchAll />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Router
