import React, { Component } from 'react'
import { Modal, Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { clearCompany, clearUser } from 'ducks/auth'

class signOutConfirm extends Component {
  signOut() {
    this.props.clearCompany()
    this.props.clearUser()
  }

  render() {
    return (
      <Modal
        size="tiny"
        basic
        open={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <Modal.Header>Are you sure you want to sign out?</Modal.Header>
        <Modal.Actions>
          <Button basic color="red" inverted onClick={this.props.onClose}>
            <Icon name="remove" /> No
          </Button>
          <Button color="green" inverted onClick={this.signOut.bind(this)}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default withRouter(
  connect(null, {
    clearUser,
    clearCompany
  })(signOutConfirm)
)
