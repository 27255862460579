import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Segment, Divider, Form } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { clearUser } from 'ducks/auth'
import { toast } from 'react-toastify'

class ChangePassword extends Component {
  constructor(props) {
    super(props)

    this.initialState = {
      loading: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    }

    this.state = this.initialState
  }

  handleInputChange(prop, event, data) {
    this.setState({ [prop]: event.target.value || data.value })
  }

  handleSubmit() {
    if (this.state.loading) return
    if (this.state.newPassword !== this.state.confirmPassword) {
      toast.error("Passwords don't match.")
      return this.setState({ loading: false })
    }

    this.setState({ loading: true })
    axios()
      .patch('/users', {
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword
      })
      .then(res => {
        this.props.clearUser(res)
        toast.success('Password changed.')
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <Segment clearing>
        <h2>Change Password</h2>
        <Divider />
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Input
            label="Current Password"
            type="password"
            value={this.state.currentPassword}
            onChange={this.handleInputChange.bind(this, 'currentPassword')}
          />
          <Form.Input
            label="New Password"
            type="password"
            value={this.state.newPassword}
            onChange={this.handleInputChange.bind(this, 'newPassword')}
          />
          <Form.Input
            label="Confirm New Password"
            type="password"
            value={this.state.confirmPassword}
            onChange={this.handleInputChange.bind(this, 'confirmPassword')}
          />
          <Form.Button
            floated="right"
            loading={this.state.loading}
            color="green"
            content="Save"
          />
        </Form>
      </Segment>
    )
  }
}

export default connect(
  state => ({
    user: state.auth.user
  }),
  {
    clearUser
  }
)(ChangePassword)
