import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Segment, Divider, Form, Progress } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { updateCompany } from 'ducks/auth'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const Logo = styled.img`
  width: 150px;
  border: 1px #333 solid;
  padding: 3px;
  display: block;
  margin: 0 auto 10px auto;
`

class UploadLogo extends Component {
  constructor(props) {
    super(props)

    this.initialState = {
      loading: false,
      loadProgress: undefined,
      currentLogo: props.company.logoUrl,
      blob: undefined
    }

    this.state = this.initialState
  }

  handleFileChange(event) {
    this.setState({
      currentLogo: window.URL.createObjectURL(event.target.files[0]),
      blob: event.target.files[0]
    })
  }

  handleSubmit() {
    if (this.state.loading) return

    this.setState({ loading: true })

    const data = new window.FormData()
    data.append('logo', this.state.blob)

    axios()
      .post('/companies/upload-logo', data, {
        onUploadProgress: progressEvent => {
          const completed = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          this.setState({ loadProgress: completed })
        }
      })
      .then(res => {
        this.props.updateCompany(res)
        toast.success('Updated successfully.')
        this.setState({
          loading: false,
          loadProgress: undefined,
          blob: undefined
        })
        this.initialState = this.state
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({
          loading: false,
          loadProgress: undefined,
          blob: undefined
        })
      })
  }

  handleReset(e) {
    e.preventDefault()
    this.setState(this.initialState)
  }

  render() {
    return (
      <Segment clearing>
        <h2>Upload Logo</h2>
        <Divider />
        {this.state.currentLogo ? (
          <Logo src={this.state.currentLogo} alt="Company Logo" />
        ) : (
          <p>Logo has not been uploaded.</p>
        )}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Input type="file" onChange={this.handleFileChange.bind(this)} />
          {this.state.loadProgress && (
            <Form.Field>
              <Progress
                percent={this.state.loadProgress}
                size="tiny"
                active={this.state.loading}
                style={{ marginBottom: 0 }}
                color="green"
              />
            </Form.Field>
          )}
          <Form.Group style={{ float: 'right' }}>
            <Form.Button
              onClick={this.handleReset.bind(this)}
              content="Reset"
            />
            {this.state.blob && (
              <Form.Button
                loading={this.state.loading}
                color="green"
                content="Save"
              />
            )}
          </Form.Group>
        </Form>
      </Segment>
    )
  }
}

export default connect(
  state => ({
    company: state.auth.company
  }),
  {
    updateCompany
  }
)(UploadLogo)
