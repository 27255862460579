import React, { Component } from 'react'
import { Table, Pagination, Icon, Responsive, Label } from 'semantic-ui-react'
import moment from 'moment-timezone'
import TransactionDetails from '../TransactionDetails'

class TransactionsTable extends Component {
  constructor() {
    super()

    this.state = {
      modalIsOpen: false,
      selected: undefined
    }
  }

  render() {
    return (
      <Table striped selectable>
        <TransactionDetails
          open={this.state.modalIsOpen}
          getTransactions={this.props.getTransactions}
          transaction={this.state.selected}
          onClose={() => this.setState({ modalIsOpen: false })}
        />

        <Responsive minWidth="800" as={Table.Header}>
          <Table.Row>
            <Table.HeaderCell>Customer Name</Table.HeaderCell>
            <Table.HeaderCell>Customer Email</Table.HeaderCell>
            <Table.HeaderCell>Purchase Date</Table.HeaderCell>
            <Table.HeaderCell>Total Charge</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
          </Table.Row>
        </Responsive>

        <Table.Body>
          {!this.props.transactions.length && (
            <Table.Row>
              <Table.Cell colSpan="100" textAlign="center">
                No transactions found.
              </Table.Cell>
            </Table.Row>
          )}

          {this.props.transactions.map(transaction => (
            <Table.Row
              key={transaction.id}
              onClick={() =>
                this.setState({
                  modalIsOpen: true,
                  selected: transaction
                })
              }
              style={{ cursor: 'pointer' }}
            >
              <Table.Cell>{transaction.customerName}</Table.Cell>
              <Table.Cell>{transaction.customerEmail}</Table.Cell>
              <Table.Cell>
                {moment(transaction.purchasedAt).format('lll')}
              </Table.Cell>
              <Table.Cell>${transaction.orderTotal.toFixed(2)}</Table.Cell>
              <Table.Cell textAlign="right" collapsing>
                {transaction.stripeRefundId ? (
                  <Label color="yellow" content="Refunded" />
                ) : transaction.refundRequestReason &&
                  !transaction.refundRejectionReason ? (
                  <Label color="red" content="Refund Requested" />
                ) : transaction.tickets.find(t => t.redeemedAt) ? (
                  <Label color="green" content="Redeemed" />
                ) : (
                  <Label content="Processed" />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="100" textAlign="right">
              <Pagination
                onPageChange={this.props.changePage}
                nextItem={{ content: <Icon name="chevron right" /> }}
                prevItem={{ content: <Icon name="chevron left" /> }}
                lastItem={null}
                firstItem={null}
                ellipsisItem={null}
                boundaryRange={0}
                size="mini"
                activePage={this.props.currentPage}
                totalPages={this.props.totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }
}

export default TransactionsTable
