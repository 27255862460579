import React, { Component } from 'react'
import {
  Modal,
  Button,
  Message,
  Form,
  Popup,
  Icon,
  Input
} from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class NewTicketType extends Component {
  constructor() {
    super()

    this.initialState = {
      loading: false,
      error: '',
      name: '',
      price: '',
      minimumTicketCount: ''
    }

    this.state = this.initialState
  }

  handleInputChange(event, data) {
    this.setState({
      [event.target.name || data.name]: event.target.value || data.value
    })
  }

  submit() {
    if (!this.state.name || !this.state.price) {
      return this.setState({
        error: 'Ticket price and name must be defined.'
      })
    }

    this.setState({ loading: true })
    axios()
      .post('/ticket-types', {
        name: this.state.name,
        price: this.state.price,
        minimumTicketCount: this.state.minimumTicketCount || 0
      })
      .then(res => {
        toast.success('Ticket type created.')
        this.close.apply(this)
        this.props.getTicketTypes()
      })
      .catch(err => {
        this.setState({ loading: false, error: err.message })
      })
  }

  close() {
    this.setState(this.initialState)
    this.props.onClose()
  }

  render() {
    return (
      <Modal
        size="tiny"
        closeOnDimmerClick={false}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>New Ticket Type</Modal.Header>
        <Modal.Content>
          {this.state.error && <Message negative>{this.state.error}</Message>}

          <Form>
            <Form.Input
              label="Name"
              onChange={this.handleInputChange.bind(this)}
              value={this.state.name}
              placeholder="i.e. General Admission, Fast Pass"
              name="name"
            />
            <Form.Input
              label="Price"
              onChange={this.handleInputChange.bind(this)}
              value={this.state.price}
              icon="dollar"
              iconPosition="left"
              placeholder="20.00"
              name="price"
            />
            <Form.Group columns={3} widths="equal">
              <Form.Field>
                <label>
                  Minimum Purchase Quantity{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Minimum number of tickets required to purchase this ticket type. Useful for creating group discounts."
                  />
                </label>
                <Input
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.minimumTicketCount}
                  placeholder="0"
                  name="minimumTicketCount"
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close.bind(this)}>Cancel</Button>
          <Button
            color="green"
            loading={this.state.loading}
            onClick={this.submit.bind(this)}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default NewTicketType
