import React, { Component } from 'react'
import { Icon, Divider, Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { connect } from 'react-redux'

const Wrapper = styled.div`
  color: #f2f2f2;
  padding-top: 15px;
`

const Head = styled.h1`
  margin: 0;
`

const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 5px;
`

class Header extends Component {
  render() {
    const { company } = this.props
    return (
      <Wrapper>
        <Flex>
          <Head>{company.name}</Head>
          <Button
            onClick={this.props.openGoLive}
            compact
            color={company.isLive ? 'green' : null}
            size="tiny"
          >
            <span style={{ whiteSpace: 'nowrap' }}>
              <Icon name="target" />
              {company.isLive ? 'Live' : 'Not Live'}
            </span>
          </Button>
        </Flex>
        <Divider fitted />
      </Wrapper>
    )
  }
}

export default connect(state => ({
  company: state.auth.company
}))(Header)
