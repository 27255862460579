import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Router from './Router'
import 'semantic-ui-css/semantic.min.css'
import 'react-datepicker/dist/react-datepicker.min.css'
import './index.css'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import reducers from './ducks'
import { ToastContainer } from 'react-toastify'
import SignInWrapper from './SignInWrapper'
import 'core-js/shim'

const rootReducer = combineReducers(reducers)

class App extends Component {
  render() {
    return (
      <Provider store={createStore(rootReducer)}>
        <SignInWrapper>
          <Router />
          <ToastContainer position="top-center" />
        </SignInWrapper>
      </Provider>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
