import React, { useEffect, useState } from 'react'
import {
  Html5QrcodeScanType,
  Html5Qrcode,
  Html5QrcodeSupportedFormats
} from 'html5-qrcode'
import { toast } from 'react-toastify'
import { Button, Icon } from 'semantic-ui-react'

const Scanner = props => {
  const [scanner, setScanner] = useState()

  useEffect(() => {
    if (!props.scanning) return
    const scanner = new Html5Qrcode('QR-SCANNER')

    scanner
      .start(
        { facingMode: 'environment' },
        {
          formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
          qrbox: { width: 350, height: 350 },
          supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
        },
        props.onScanSuccess
      )
      .then(() => setScanner(scanner))
      .catch(error => {
        toast.error('Unable to open camera.')
        console.error('Unable to open camera. ', error)
      })

    return () => {
      if (!scanner.isScanning) return
      scanner
        .stop()
        .then(() => scanner.clear())
        .catch(error => {
          console.error('Failed to close html5QrcodeScanner. ', error)
        })
    }
  }, [props.onScanSuccess, props.scanning])

  const toggleTorch = () => {
    if (!scanner || !scanner.isScanning) return
    const torch = scanner.getRunningTrackCameraCapabilities().torchFeature()
    if (!torch.isSupported()) return alert('Unable to turn on flashlight.')
    torch.apply(!torch.value())
  }

  if (!props.scanning) return null
  return (
    <div>
      <div id="QR-SCANNER" style={{ marginTop: 8, marginBottom: 8 }} />
      <Button onClick={toggleTorch} fluid>
        <Icon size="large" name="sun" />
      </Button>
    </div>
  )
}

export default Scanner
