import axios from 'axios'

export default () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { Authorization: window.localStorage.getItem('token') }
  })

  instance.interceptors.response.use(
    response => {
      return response.data
    },
    error => {
      if (error.response.data.error && error.response.data.error.message) {
        throw error.response.data.error
      }
      if (
        !error.response ||
        !error.response.data ||
        !error.response.data.message
      ) {
        throw new Error(
          'Oops! Looks like a server error. Contact support if the problem persists.'
        )
      }
      throw error.response.data
    }
  )

  return instance
}
