import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Segment, Divider, Button } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class BankAccountSettings extends Component {
  state = {
    stripeLink: ''
  }

  componentDidMount() {
    axios()
      .get('/companies/stripe-link')
      .then(res => {
        this.setState({ stripeLink: res.url })
      })
      .catch(err => {
        toast.error(err.message)
      })
  }

  render() {
    return (
      <Segment clearing>
        <h2>Stripe Account Settings</h2>
        <Divider />
        <p>
          Stripe connection status:{' '}
          <strong>{this.props.company.stripeStatus}</strong>
        </p>

        {this.state.stripeLink && (
          <Button
            color="blue"
            floated="right"
            as="a"
            href={this.state.stripeLink}
          >
            Stripe Account
          </Button>
        )}
      </Segment>
    )
  }
}

export default connect(state => ({
  company: state.auth.company
}))(BankAccountSettings)
