import React, { Component } from 'react'
import { Icon, Sidebar, Menu, Container } from 'semantic-ui-react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import AdminCompanyChange from './components/AdminCompanyChange'
import SignOutConfirm from './components/SignOutConfirm'
import Footer from './components/Footer'
import Header from './components/Header'
import Notifications from './components/Notifications'
import GoLive from './components/GoLive'

const Content = styled.div`
  min-height: calc(100vh - 42.85px);
  padding-bottom: 250px;
`

class Wrapper extends Component {
  constructor() {
    super()

    this.state = {
      sidebarIsVisible: false,
      companyModalIsVisible: false,
      signOutModalIsVisible: false,
      goLiveModalIsOpen: false
    }
  }

  toggleSidebar() {
    this.setState({ sidebarIsVisible: !this.state.sidebarIsVisible })
  }

  render() {
    const url = this.props.location.pathname
    const user = this.props.user

    return (
      <div>
        <AdminCompanyChange
          isOpen={this.state.companyModalIsVisible}
          onClose={() => this.setState({ companyModalIsVisible: false })}
        />

        <SignOutConfirm
          isOpen={this.state.signOutModalIsVisible}
          onClose={() => this.setState({ signOutModalIsVisible: false })}
        />

        <GoLive
          onClose={() => this.setState({ goLiveModalIsOpen: false })}
          isOpen={this.state.goLiveModalIsOpen}
        />

        <Menu attached inverted size="large">
          <Menu.Item icon="bars" onClick={this.toggleSidebar.bind(this)} />
          {this.props.user.isSuperAdmin && (
            <Menu.Item
              onClick={() => this.setState({ companyModalIsVisible: true })}
            >
              Change Company
            </Menu.Item>
          )}
          <Menu.Item
            position="right"
            onClick={() => this.setState({ signOutModalIsVisible: true })}
          >
            Sign Out
          </Menu.Item>
        </Menu>

        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="overlay"
            width="thin"
            visible={this.state.sidebarIsVisible}
            icon="labeled"
            onClick={() => this.setState({ sidebarIsVisible: false })}
            vertical
            inverted
          >
            {user.isAdmin && (
              <Menu.Item
                as={Link}
                to="/dashboard/overview"
                active={url === '/dashboard/overview'}
              >
                <Icon name="chart line" />
                Overview
              </Menu.Item>
            )}
            {user.isAdmin && (
              <Menu.Item
                as={Link}
                to="/dashboard/transactions"
                active={url === '/dashboard/transactions'}
              >
                <Icon name="dollar" />
                Transactions
              </Menu.Item>
            )}
            {user.isAdmin && (
              <Menu.Item
                as={Link}
                to="/dashboard/ticket-types"
                active={url === '/dashboard/ticket-types'}
              >
                <Icon name="ticket" />
                Ticket Types
              </Menu.Item>
            )}
            {user.isAdmin && (
              <Menu.Item
                as={Link}
                to="/dashboard/promo-codes"
                active={url === '/dashboard/promo-codes'}
              >
                <Icon name="percent" />
                Promo Codes
              </Menu.Item>
            )}
            {user.isAdmin && (
              <Menu.Item
                as={Link}
                to="/dashboard/time-slots"
                active={url === '/dashboard/time-slots'}
              >
                <Icon name="time" />
                Time Slots
              </Menu.Item>
            )}
            {user.isAdmin && (
              <Menu.Item
                as={Link}
                to="/dashboard/team-members"
                active={url === '/dashboard/team-members'}
              >
                <Icon name="users" />
                Team Members
              </Menu.Item>
            )}
            <Menu.Item
              as={Link}
              to="/dashboard/ticket-scanner"
              active={url === '/dashboard/ticket-scanner'}
            >
              <Icon name="barcode" />
              Ticket Scanner
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/dashboard/settings"
              active={url === '/dashboard/settings'}
            >
              <Icon name="settings" />
              Settings
            </Menu.Item>
          </Sidebar>
          <Sidebar.Pusher
            onClick={() => this.setState({ sidebarIsVisible: false })}
          >
            <Content>
              <Container>
                <Header
                  openGoLive={() => this.setState({ goLiveModalIsOpen: true })}
                />
                <Notifications
                  openGoLive={() => this.setState({ goLiveModalIsOpen: true })}
                />
                {this.props.children}
              </Container>
              <Footer />
            </Content>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    )
  }
}

export default withRouter(
  connect(state => ({
    user: state.auth.user,
    company: state.auth.company
  }))(Wrapper)
)
