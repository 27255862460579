import React, { useEffect, useState } from 'react'
import { Modal, Button, Icon, Label, Divider } from 'semantic-ui-react'
import moment from 'moment-timezone'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

const TransactionModal = props => {
  const { transaction, onClose, open } = props
  const [tickets, setTickets] = useState([])

  useEffect(() => {
    if (!transaction) return
    setTickets(transaction.tickets)
  }, [transaction])

  const updateTicket = (ticketId, updates) => {
    setTickets(tickets =>
      tickets.map(t => (t.id === ticketId ? { ...t, ...updates } : t))
    )
  }

  const redeem = (ticketId, force, handleErrors = true) => {
    updateTicket(ticketId, { loading: true })

    return axios()
      .patch('/transactions/redeem/' + ticketId, null, {
        params: { force: force }
      })
      .then(res => updateTicket(ticketId, res))
      .catch(err => {
        if (!handleErrors) throw err
        if (err.canForce) {
          const confirm = window.confirm(
            'This ticket is not valid for the current time slot. Would you like to redeem it anyways?'
          )
          if (confirm) redeem(ticketId, true)
        } else {
          toast.error(err.message)
        }
      })
      .finally(() => updateTicket(ticketId, { loading: false }))
  }

  const redeemAll = async (force = false) => {
    try {
      for (const ticket of tickets) {
        if (!ticket.redeemedAt && !ticket.refundedAt) {
          await redeem(ticket.id, force, false)
        }
      }
    } catch (err) {
      if (err.canForce) {
        const confirm = window.confirm(
          'These tickets are not valid for the current time slot. Would you like to redeem them anyways?'
        )
        if (confirm) redeemAll(true)
      } else {
        toast.error(err.message)
      }
    }
  }

  const right = {
    textAlign: 'right',
    whiteSpace: 'nowrap',
    paddingRight: 10
  }

  if (!transaction) return null

  return (
    <Modal size="tiny" closeOnDimmerClick open={open} onClose={onClose}>
      <Modal.Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          Transaction Details
          <div style={{ textAlign: 'center' }}>
            {transaction.stripeRefundId && (
              <Label color="yellow" content="Refunded" />
            )}
            {!transaction.stripeRefundId &&
              transaction.refundRequestReason &&
              !transaction.refundRejectionReason && (
                <Label color="yellow" content="Refund Requested" />
              )}
          </div>
        </div>
      </Modal.Header>

      <Modal.Content>
        <table style={{ borderSpacing: '0 7px' }}>
          <tbody>
            <tr>
              <td style={right}>
                <strong>Name:</strong>
              </td>
              <td>{transaction.customerName}</td>
            </tr>
            <tr>
              <td style={right}>
                <strong>Email:</strong>
              </td>
              <td>{transaction.customerEmail}</td>
            </tr>
            <tr>
              <td style={right}>
                <strong>Purchase Date:</strong>
              </td>
              <td>{moment(transaction.purchasedAt).format('LLL')}</td>
            </tr>

            <tr>
              <td style={right}>
                <strong>Time Slot:</strong>
              </td>
              <td>
                {transaction.timeSlot ? (
                  <span>
                    {moment(transaction.timeSlot.startAt).format('lll')}{' '}
                    <Icon name="long arrow alternate right" />
                    {moment(transaction.timeSlot.endAt).format('lll')}
                  </span>
                ) : (
                  'N/A'
                )}
              </td>
            </tr>
            <tr>
              <td style={right}>
                <strong>Ticket Type:</strong>
              </td>
              <td>{transaction.ticketType.name}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Content>

      <Divider fitted />
      <Modal.Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          Tickets
          <Button
            compact
            positive={!tickets.every(t => t.redeemedAt || t.refundedAt)}
            disabled={tickets.every(t => t.redeemedAt || t.refundedAt)}
            onClick={() => redeemAll()}
          >
            Redeem All
          </Button>
        </div>
      </Modal.Header>
      <Modal.Content>
        <table style={{ borderSpacing: '0 7px' }}>
          <tbody>
            {tickets.map((ticket, idx) => (
              <tr key={ticket.id}>
                <td style={right}>
                  <strong>Ticket #{idx + 1}:</strong>
                </td>
                <td>
                  <Button
                    size="tiny"
                    positive={!ticket.redeemedAt && !ticket.refundedAt}
                    disabled={Boolean(ticket.redeemedAt || ticket.refundedAt)}
                    loading={ticket.loading}
                    onClick={() => redeem(ticket.id)}
                  >
                    {ticket.redeemedAt
                      ? `Redeemed - ${moment(ticket.redeemedAt).format('lll')}`
                      : ticket.refundedAt
                      ? `Refunded - ${moment(ticket.refundedAt).format('lll')}`
                      : 'Redeem'}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={onClose}>Done</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TransactionModal
