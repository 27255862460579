export default [
  '#2185D0',
  '#82CA9D',
  '#DC6A53',
  '#F28A36',
  '#34C8A6',
  '#FFCD56',
  '#DE3145',
  '#8884D8',
  '#C4AC39',
  '#42B0B4'
]
