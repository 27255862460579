import React, { Component } from 'react'
import { Table, Button, Confirm, Icon } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

class TicketTypesTable extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      deleteConfirm: false,
      restoreConfirm: false,
      selected: undefined
    }
  }

  edit(disable) {
    this.setState({ loading: true })
    axios()
      .patch('/ticket-types/' + this.state.selected, {
        disabled: disable
      })
      .then(res => {
        this.props.getTicketTypes()
        this.setState({
          loading: false,
          deleteConfirm: false,
          restoreConfirm: false
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  render() {
    return (
      <Table striped>
        <Confirm
          onCancel={() => this.setState({ deleteConfirm: false })}
          onConfirm={this.edit.bind(this, true)}
          size="tiny"
          content="Are you sure you want to delete this ticket type?"
          header="Are you sure?"
          confirmButton={{
            loading: this.state.loading,
            negative: true,
            content: 'Delete it!',
            icon: 'trash'
          }}
          open={this.state.deleteConfirm}
        />
        <Confirm
          onCancel={() => this.setState({ restoreConfirm: false })}
          onConfirm={this.edit.bind(this, false)}
          size="tiny"
          content="Are you sure you want to restore this ticket type?"
          header="Are you sure?"
          confirmButton={{
            loading: this.state.loading,
            positive: true,
            content: 'Restore it!',
            icon: 'undo'
          }}
          open={this.state.restoreConfirm}
        />

        <Table.Body>
          {!this.props.ticketTypes.length && (
            <Table.Row>
              <Table.Cell textAlign="center">
                <h4>No ticket types found.</h4>
              </Table.Cell>
            </Table.Row>
          )}
          {this.props.ticketTypes.map(ticketType => (
            <Table.Row key={ticketType.id}>
              <Table.Cell>
                {ticketType.disabled && <Icon color="red" name="ban" />}
                <strong>{ticketType.name}</strong>
                {ticketType.minimumTicketCount > 0 &&
                  ` (${ticketType.minimumTicketCount}+)`}
              </Table.Cell>
              <Table.Cell>${ticketType.price.toFixed(2)}</Table.Cell>
              <Table.Cell
                onClick={() => this.setState({ selected: ticketType.id })}
                textAlign="right"
              >
                {!ticketType.disabled ? (
                  <Button
                    size="small"
                    icon="trash"
                    compact
                    color="red"
                    onClick={() => this.setState({ deleteConfirm: true })}
                    content="Delete"
                  />
                ) : (
                  <Button
                    size="small"
                    icon="undo"
                    compact
                    color="green"
                    onClick={() => this.setState({ restoreConfirm: true })}
                    content="Restore"
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )
  }
}

export default TicketTypesTable
