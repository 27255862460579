import React, { Component } from 'react'
import { Message, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { updateNotificationsState } from 'ducks/notifications'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

class Notifications extends Component {
  constructor() {
    super()

    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    axios()
      .get('/ticket-types/' + this.props.company.id)
      .then(res => {
        this.setState({ loading: false })
        this.props.updateNotificationsState({
          hasTicketTypes: res.length
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  render() {
    const props = this.props

    if (this.state.loading) {
      return null
    }

    if (props.company.stripeStatus !== 'active') {
      return (
        <div>
          <Message error style={{ margin: 0, marginTop: 10 }} size="small">
            Your Stripe account isn't active so you won't be able to receive
            payouts. <Link to="/dashboard/settings">Click Here</Link>
          </Message>
        </div>
      )
    }

    if (!this.props.hasTicketTypes) {
      return (
        <div>
          <Message error style={{ margin: 0, marginTop: 10 }} size="small">
            Create at least one ticket type to start selling.{' '}
            <Link to="/dashboard/ticket-types">Click Here</Link>
          </Message>
        </div>
      )
    }

    if (!props.company.isLive) {
      return (
        <div>
          <Message error style={{ margin: 0, marginTop: 10 }} size="small">
            Go live to start selling tickets.{' '}
            <Button onClick={props.openGoLive}>Click Here</Button>
          </Message>
        </div>
      )
    }

    return null
  }
}

export default connect(
  state => ({
    company: state.auth.company,
    hasTicketTypes: state.notifications.hasTicketTypes
  }),
  {
    updateNotificationsState
  }
)(Notifications)
