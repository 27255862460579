import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Segment,
  Divider,
  Form,
  Input,
  Popup,
  Icon,
  Responsive,
  Button
} from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { updateCompany } from 'ducks/auth'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import backgroundOptions from './backgrounds.json'

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin: 15px 0;
`
const BackgroundWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
`
const BackgroundOption = styled.img`
  width: calc(33.333% - 10px);
  height: 100%;
  margin-bottom: 10px;
  opacity: ${props => (props.selected ? '1' : '0.5')};
  cursor: pointer;
  -webkit-transform: translateZ(0);
  transition: 0.2s opacity;
  &:hover {
    opacity: 1;
  }
`

class TicketPortalSettings extends Component {
  constructor(props) {
    super(props)

    this.initialState = {
      loading: false,
      salesTax: props.company.salesTax * 100,
      additionalInfo: props.company.additionalInfo || '',
      additionalTicketInfo: props.company.additionalTicketInfo || '',
      frontEndUrl: props.company.frontEndUrl || '',
      passFees: props.company.passFees,
      offerRefunds: props.company.offerRefunds,
      ticketPortalBackground: props.company.ticketPortalBackground
    }

    this.state = this.initialState
  }

  handleInputChange(prop, event, data) {
    this.setState({ [prop]: event.target.value || data.value || data.checked })
  }

  handleSubmit() {
    if (this.state.loading) return

    if (!/^[a-z0-9-]+$/.test(this.state.frontEndUrl)) {
      return toast.error('URL can only contain lowercase letters and dashes.')
    }

    this.setState({ loading: true })
    axios()
      .patch('/companies', {
        salesTax: this.state.salesTax / 100,
        additionalInfo: this.state.additionalInfo,
        additionalTicketInfo: this.state.additionalTicketInfo,
        frontEndUrl: this.state.frontEndUrl,
        passFees: this.state.passFees,
        offerRefunds: this.state.offerRefunds,
        ticketPortalBackground: this.state.ticketPortalBackground || undefined
      })
      .then(res => {
        this.props.updateCompany(res)
        toast.success('Updated successfully.')
        this.setState({ loading: false })
        this.initialState = this.state
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({ loading: false })
      })
  }

  handleReset(e) {
    e.preventDefault()
    this.setState(this.initialState)
  }

  render() {
    return (
      <Segment clearing>
        <h2>Ticket Portal Settings</h2>
        <Divider />
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Field>
            <label>
              Ticket Portal URL{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="URL where your customers will go to purchase tickets. Must only include   lowercase letters and dashes."
              />
            </label>
            <Responsive as="label" maxWidth={1200}>
              {process.env.REACT_APP_TICKET_PORTAL_URL + '/'}
            </Responsive>
            <Responsive
              minWidth={1200}
              as={Input}
              label={process.env.REACT_APP_TICKET_PORTAL_URL + '/'}
              value={this.state.frontEndUrl}
              onChange={this.handleInputChange.bind(this, 'frontEndUrl')}
            />
            <Responsive
              maxWidth={1200}
              as={Input}
              value={this.state.frontEndUrl}
              onChange={this.handleInputChange.bind(this, 'frontEndUrl')}
            />
          </Form.Field>
          <Form.Field>
            <label>
              Sales Tax{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Leave at 0 if sales tax is included in ticket price."
              />
            </label>
            <Input
              label="%"
              labelPosition="right"
              value={this.state.salesTax}
              onChange={this.handleInputChange.bind(this, 'salesTax')}
            />
          </Form.Field>
          <CheckBoxWrapper>
            <label>Pass convenience fees to customer</label>
            <Form.Checkbox
              checked={this.state.passFees}
              toggle
              floated="right"
              onChange={this.handleInputChange.bind(this, 'passFees')}
            />
          </CheckBoxWrapper>
          <CheckBoxWrapper>
            <label>Offer refunds on ticket sales</label>
            <Form.Checkbox
              checked={this.state.offerRefunds}
              toggle
              onChange={this.handleInputChange.bind(this, 'offerRefunds')}
            />
          </CheckBoxWrapper>
          <Form.Field>
            <label>
              Customized Greeting{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Shown on the first page your customers see when buying tickets."
              />
            </label>
            <Form.TextArea
              value={this.state.additionalInfo}
              onChange={this.handleInputChange.bind(this, 'additionalInfo')}
            />
          </Form.Field>
          <Form.Field>
            <label>
              Ticket Message{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Shown on each ticket after your customers make a purchase."
              />
            </label>
            <Form.TextArea
              value={this.state.additionalTicketInfo}
              onChange={this.handleInputChange.bind(
                this,
                'additionalTicketInfo'
              )}
            />
          </Form.Field>
          <Form.Field style={{ marginBottom: 0 }}>
            <label>
              Background{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Custom ticket portal background image."
              />
            </label>
          </Form.Field>
          <BackgroundWrapper>
            {backgroundOptions.map(option => (
              <BackgroundOption
                selected={this.state.ticketPortalBackground === option.src}
                key={option.src}
                src={option.thumb}
                onClick={() =>
                  this.setState({ ticketPortalBackground: option.src })
                }
              />
            ))}
            <Button
              onClick={() => this.setState({ ticketPortalBackground: '' })}
            >
              No Background
            </Button>
          </BackgroundWrapper>
          <Form.Group style={{ float: 'right' }}>
            <Form.Button
              onClick={this.handleReset.bind(this)}
              content="Reset"
            />
            <Form.Button
              loading={this.state.loading}
              color="green"
              content="Save"
            />
          </Form.Group>
        </Form>
      </Segment>
    )
  }
}

export default connect(
  state => ({
    company: state.auth.company
  }),
  { updateCompany }
)(TicketPortalSettings)
